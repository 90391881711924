import { graphql, useKnapsakApi } from '@knapsak/web/shared/data-access';
import { Container } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import TripInvitationListItem from './TripInvitationListItem';

const MyTripInvitations = graphql(`
  query MyTripInvitations {
    my {
      tripInvitations {
        nodes {
          ...MyTripInvitations_TripInvitationFragment
        }
      }
    }
  }
`);

export const TripInvitationList = () => {
  const knapsakApiClient = useKnapsakApi();
  const { data, isLoading } = useQuery(
    ['my', 'tripInvitations'],
    async () => await knapsakApiClient.request(MyTripInvitations)
  );

  const invitations = data?.my.tripInvitations?.nodes ?? [];

  if (isLoading) return <Container>Loading...</Container>;

  if (invitations.length === 0)
    return <Container>You have no trip invitations.</Container>;

  return (
    <>
      {invitations.map((invitation, idx) => (
        <TripInvitationListItem key={idx} invitation={invitation} />
      ))}
    </>
  );
};
