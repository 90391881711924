import { useGearFormContext } from '@knapsak/web/gear/data-access';
import { WeightUnit } from '@knapsak/web/gear/models';
import { convertWeight } from '@knapsak/web/gear/util';

export const useWeightInput = () => {
  const form = useGearFormContext();

  const changeWeight = (value: number | string) => {
    form.setFieldValue('weight.value', Number(value));
  };

  const changeUnit = (to: WeightUnit) => {
    const { weight } = form.values;
    const convertedWeight = convertWeight(weight, to);
    form.setFieldValue('weight', convertedWeight);
  };

  return {
    form,
    changeWeight,
    changeUnit
  };
};
