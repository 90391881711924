import styled from '@emotion/styled';
import { Grid } from '@mantine/core';
import { SortableColumn, SortDirection } from './SortableColumn';

export type GearSortOption = {
  brand: SortDirection;
  name: SortDirection;
  createdOn: SortDirection;
};

type HeaderProps = {
  sort: GearSortOption;
  onSortChanged: (sort: GearSortOption) => void;
};

export const Header = (props: HeaderProps) => {
  const { sort, onSortChanged } = props;

  return (
    <Root align="center">
      <Grid.Col span={2}>
        <SortableColumn
          sortOption={sort.brand}
          text="Brand"
          onSortChanged={(brandSort) => {
            onSortChanged({ ...sort, brand: brandSort });
          }}
        />
      </Grid.Col>
      <Grid.Col span={2}>
        <SortableColumn
          sortOption={sort.name}
          text="Name"
          onSortChanged={(nameSort) => {
            onSortChanged({ ...sort, name: nameSort });
          }}
        />
      </Grid.Col>
      <Grid.Col span={1} ta="right">
        Quantity
      </Grid.Col>
      <Grid.Col span={2} ta="right">
        Weight
      </Grid.Col>
      <Grid.Col span={4}>Tags</Grid.Col>
      <Grid.Col span={1}></Grid.Col>
    </Root>
  );
};

export const Root = styled(Grid)`
  font-weight: bold;
  background-color: var(--mantine-primary-color-filled);
  color: var(--mantine-color-white);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 1rem;
`;
