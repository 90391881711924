import { CallbackPage } from '@knapsak/web/auth/feature-callback';
import { LoginPage } from '@knapsak/web/auth/feature-login';
import { LogoutPage } from '@knapsak/web/auth/feature-logout';
import { LandingPage } from '@knapsak/web/misc/feature-landing';
import { ROUTES } from '@knapsak/web/navigation/util-routes';

export const PublicRoutes = [
  {
    index: true,
    element: <LandingPage />
  },
  {
    path: ROUTES.Login(),
    element: <LoginPage />
  },
  {
    path: ROUTES.Logout(),
    element: <LogoutPage />
  },
  {
    path: ROUTES.Callback(),
    element: <CallbackPage />
  }
];
