import { PropsWithChildren } from 'react';

interface Props {
  condition: boolean;
}

export const RenderIf = (props: PropsWithChildren<Props>) => {
  const { condition, children } = props;

  if (condition) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return null;
};
