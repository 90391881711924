import { RenderIf } from '@knapsak/shared/ui-render-if';
import { graphql, useKnapsakApi } from '@knapsak/web/shared/data-access';
import { ProblemBox } from '@knapsak/web/shared/feature-problems';
import { SimpleOops } from '@knapsak/web/shared/ui-errors';
import { Button, Container, Group, Input } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals/lib/context';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

const RenameTripMutation = graphql(`
  mutation RenameTrip($input: RenameTripInput!) {
    renameTrip(input: $input) {
      ... on RenameTripPayload {
        __typename
        tripId
        name
      }

      ... on RenameTripFailure {
        __typename
        problems {
          ... on IProblem {
            ...ProblemBox_ProblemFragment
          }
        }
      }
    }
  }
`);

export const RenameTripModal = (
  props: ContextModalProps<{
    tripId: string;
    tripName: string;
    onSuccess: (tripId: string, name: string) => void;
  }>
) => {
  const {
    id,
    context,
    innerProps: { tripId, tripName, onSuccess }
  } = props;

  const knapsakApiClient = useKnapsakApi();
  const [name, setName] = useState(tripName);
  const { data, mutate, isError } = useMutation(
    async (input: any) =>
      await knapsakApiClient.request(RenameTripMutation, { input }),
    {
      onSuccess: (data) => {
        if (data?.renameTrip?.__typename === 'RenameTripPayload') {
          onSuccess(data.renameTrip.tripId, data.renameTrip.name);
          context.closeModal(id);
        }
      }
    }
  );

  const problems =
    data?.renameTrip?.__typename === 'RenameTripFailure'
      ? data?.renameTrip?.problems
      : [];

  return (
    <Container p="xs">
      <ProblemBox problems={problems} />
      <RenderIf condition={isError}>
        <SimpleOops />
      </RenderIf>
      <Input
        type="text"
        defaultValue={tripName}
        onChange={(e) => setName(e.target.value)}
      />
      <Group justify="center">
        <Button mt="sm" onClick={() => mutate({ tripId, name })}>
          Rename
        </Button>
      </Group>
    </Container>
  );
};
