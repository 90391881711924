import styled from '@emotion/styled';
import { Space, Text, Title } from '@mantine/core';
import { CreateTripForm } from './components/CreateTripForm';

export const NewTripPage = () => {
  return (
    <Container>
      <Space mt="xl" />
      <FormBox>
        <Title order={2}>Create a new Trip</Title>
        <Text fz="sm" mt="sm">
          Create a place to plan your route, create an itinerary, monitor
          conditions, and collaborate with your team. You can invite up to 5
          people to your trip.
        </Text>
        <Text fz="sm" mt="sm">
          These settings can always be changed later when editing your trip.
        </Text>
        <Space mt="xl" />
        <CreateTripForm />
      </FormBox>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const FormBox = styled.div`
  width: 60%;
  margin-top: 2rem;
  background-color: #272a31;
  border-radius: 1rem;
  padding: 2rem;
`;
