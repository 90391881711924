import styled from '@emotion/styled';
import { Gear } from '@knapsak/web/gear/models';
import { ActionIcon, Grid, Group, PillGroup, Tooltip } from '@mantine/core';
import { MdOutlineDelete } from 'react-icons/md';
import { GearTag } from './GearTag';

type ReadOnlyGearRowProps = {
  gear: Gear;
  onEditClicked: (gear: Gear) => void;
  onDeleteClicked: () => void;
};

const ColumnSpan = { base: 6, md: 2 };
const QuantityColumnSpan = { base: 6, md: 1 };
const TagsColumnSpan = { base: 6, md: 4 };
const WeightColumnSpan = { base: 6, md: 2 };
const ActionsColumnSpan = { base: 6, md: 1 };

export const ReadOnlyGearRow = (props: ReadOnlyGearRowProps) => {
  const { gear, onEditClicked, onDeleteClicked } = props;

  const editClicked = (e: React.MouseEvent) => {
    e.stopPropagation();
    onEditClicked(gear);
  };

  const deleteClicked = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDeleteClicked();
  };

  return (
    <HoverGrid gutter="sm" align="center" onClick={editClicked}>
      <Grid.Col span={6} hiddenFrom="md">
        <b>Brand:</b>
      </Grid.Col>
      <Grid.Col span={ColumnSpan}>{gear.brand}</Grid.Col>
      <Grid.Col span={6} hiddenFrom="md">
        <b>Name:</b>
      </Grid.Col>
      <Grid.Col span={ColumnSpan}>
        <b>{gear.name}</b>
      </Grid.Col>
      <Grid.Col span={6} hiddenFrom="md">
        <b>Quantity:</b>
      </Grid.Col>
      <Grid.Col span={QuantityColumnSpan} ta="right">
        {gear.quantity}
      </Grid.Col>
      <Grid.Col span={6} hiddenFrom="md">
        <b>Weight:</b>
      </Grid.Col>
      <Grid.Col span={WeightColumnSpan} ta="right">
        {gear.weight.value.toFixed(2)} {gear.weight.unit}
      </Grid.Col>
      <Grid.Col span={6} hiddenFrom="md">
        <b>Tags:</b>
      </Grid.Col>
      <Grid.Col span={TagsColumnSpan}>
        <PillGroup>
          {gear.tags.map((tag) => (
            <GearTag key={`${gear.id}-${tag}`} name={tag} />
          ))}
        </PillGroup>
      </Grid.Col>
      <Grid.Col span={6} hiddenFrom="md">
        <b>Action:</b>
      </Grid.Col>
      <Grid.Col span={ActionsColumnSpan}>
        <Group justify="right" gap="xs">
          <Tooltip label="Delete" className="HoverGrid-delete">
            <ActionIcon color="red" onClick={deleteClicked}>
              <MdOutlineDelete />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Grid.Col>
    </HoverGrid>
  );
};

const HoverGrid = styled(Grid)`
  &:hover {
    background-color: var(--mantine-primary-color-light);
    cursor: pointer;

    .HoverGrid-delete {
      visibility: visible;
    }
  }

  .HoverGrid-delete {
    visibility: hidden;
  }
`;
