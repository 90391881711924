import { RenderIf } from '@knapsak/shared/ui-render-if';
import { ROUTES } from '@knapsak/web/navigation/util-routes';
import {
  FragmentType,
  getFragmentData,
  graphql
} from '@knapsak/web/shared/data-access';
import { Avatar, Tooltip } from '@mantine/core';
import { FaLock } from 'react-icons/fa';
import { MdPublic } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';

export const TripSummaryFragment = graphql(`
  fragment TripSummary on Trip {
    id
    privacyLevel
    name
    startTime
    endTime
    members {
      profile {
        nickname
        picture
      }
      userId
      roles
    }
  }
`);

interface Props {
  trip: FragmentType<typeof TripSummaryFragment>;
}

export default function TripListItem(props: Props) {
  const trip = getFragmentData(TripSummaryFragment, props.trip);
  const startTime = new Date(trip.startTime);
  const endTime = new Date(trip.endTime);
  const duration = endTime.getTime() - startTime.getTime();
  const navigate = useNavigate();

  return (
    <tr key={trip.id}>
      <td>
        <RenderIf condition={trip.privacyLevel === 'PUBLIC'}>
          <MdPublic />
        </RenderIf>
        <RenderIf condition={trip.privacyLevel === 'PRIVATE'}>
          <FaLock />
        </RenderIf>
      </td>
      <td>
        {startTime.toLocaleString('default', {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        })}
      </td>
      <td>
        <Link to={ROUTES.TripDetails(trip.id)}>{trip.name}</Link>
      </td>
      <td>{getDuration(duration)}</td>
      <td>
        <Avatar.Group spacing="sm">
          {trip.members.map((member) => (
            <Tooltip
              key={member.userId}
              label={member.profile.nickname}
              position="bottom"
            >
              <Avatar src={member.profile.picture} radius="xl" />
            </Tooltip>
          ))}
        </Avatar.Group>
      </td>
    </tr>
  );
}

const getDuration = (timespan: number) => {
  const days = Math.floor(timespan / (1000 * 60 * 60 * 24));
  timespan -= days * (1000 * 60 * 60 * 24);

  const hours = Math.floor(timespan / (1000 * 60 * 60));
  timespan -= hours * (1000 * 60 * 60);

  const mins = Math.floor(timespan / (1000 * 60));
  timespan -= mins * (1000 * 60);

  const seconds = Math.floor(timespan / 1000);
  timespan -= seconds * 1000;

  return `${days}d ${hours}h ${mins}m ${seconds}s`;
};
