import { useAuth } from '@knapsak/web/auth/data-access';
import { Text } from '@mantine/core';
import { useEffect } from 'react';

export const LogoutPage = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  return <Text>Logging out...</Text>;
};
