import { useAuth } from '@knapsak/web/auth/data-access';
import { Navigate, Outlet } from 'react-router-dom';

export const ProtectedRoute = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return (
      <Navigate
        to={`auth/login?redirect=${encodeURIComponent(
          window.location.pathname
        )}`}
        replace
      />
    );
  }

  return <Outlet />;
};
