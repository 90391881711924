import { useAuth } from '@knapsak/web/auth/data-access';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const CallbackPage = () => {
  const { handleRedirectCallback, isAuthenticated } = useAuth();
  const [redirectLocation, setRedirectLocation] = useState<
    string | undefined
  >();
  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      setRedirectLocation(await handleRedirectCallback());
    };

    load();
  }, [handleRedirectCallback]);

  useEffect(() => {
    if (isAuthenticated && redirectLocation) {
      navigate(redirectLocation);
    }
  }, [navigate, isAuthenticated, redirectLocation]);

  return <div>Redirecting...</div>;
};
