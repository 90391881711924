import { GearRoomPage } from '@knapsak/web/gear/feature-gear-room';
import { ROUTES } from '@knapsak/web/navigation/util-routes';
import { NewTripPage } from '@knapsak/web/trips/feature-create-trip';
import {
  loader as MyTripsLoader,
  MyTripsPage
} from '@knapsak/web/trips/feature-my-trips';
import {
  loader as TripDetailsLoader,
  TripDetailsPage
} from '@knapsak/web/trips/feature-trip-details';
import { LoaderFunctionArgs } from 'react-router-dom';

export const PrivateRoutes = [
  {
    path: ROUTES.MyGearRoom(),
    element: <GearRoomPage />
  },
  {
    path: ROUTES.MyTrips(),
    loader: () => MyTripsLoader(false),
    element: <MyTripsPage past={false} />
  },
  {
    path: ROUTES.MyUpcomingTrips(),
    loader: () => MyTripsLoader(false),
    element: <MyTripsPage past={false} />
  },
  {
    path: ROUTES.MyPastTrips(),
    loader: () => MyTripsLoader(true),
    element: <MyTripsPage past />
  },
  {
    path: ROUTES.TripDetails(),
    loader: (args: LoaderFunctionArgs) => TripDetailsLoader(args),
    element: <TripDetailsPage />
  },
  {
    path: ROUTES.NewTrip(),
    element: <NewTripPage />
  }
];
