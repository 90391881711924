import { Button, Stack, Text, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

export const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Stack align="center" mt="xl">
      <img src="assets/404.png" alt="404" width="300px" />
      <Title>Oops! Route Not Found</Title>
      <Text>
        Looks like you've wandered off the beaten path! Don't worry, getting a
        little lost can be part of the adventure.
      </Text>
      <Text>
        Click the button below to return home and rediscover your path.
      </Text>
      <Button onClick={() => navigate('/')}>Return Home</Button>
    </Stack>
  );
};
