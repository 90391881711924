import Services from '@knapsak/shared/util-service-container';
import { graphql } from '@knapsak/web/shared/data-access';
import { QueryClient } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { LoaderFunctionArgs } from 'react-router-dom';

const getTripDetails = async (tripId: string) => {
  const query = graphql(`
    query TripDetails($tripId: UUID!) {
      my {
        trips(where: { id: { eq: $tripId } }) {
          nodes {
            ...TripDetails
          }
        }
      }
    }
  `);

  const knapsakApiClient = Services.make<GraphQLClient>('knapsakApiClient');

  const response = await knapsakApiClient.request(query, {
    tripId
  });

  return response;
};

export const getTripDetailsQuery = (tripId: string) => ({
  queryKey: ['trip', 'details', tripId],
  queryFn: async () => getTripDetails(tripId),
  staleTime: 1000 * 60
});

export const loader = async (args: LoaderFunctionArgs) => {
  const query = getTripDetailsQuery(args.params.tripId as string);
  const queryClient = Services.make<QueryClient>('queryClient');
  return await queryClient.ensureQueryData(query);
};
