import { RenderIf } from '@knapsak/shared/ui-render-if';
import {
  ActionIcon,
  AppShell,
  Burger,
  Group,
  Title,
  useComputedColorScheme,
  useMantineColorScheme
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MdOutlineDarkMode, MdOutlineLightMode } from 'react-icons/md';
import { Outlet } from 'react-router-dom';
import { Sidebar } from './Sidebar';

export function AuthenticatedLayout() {
  const [opened, { toggle }] = useDisclosure();
  const scheme = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme('dark');

  return (
    <AppShell
      header={{
        height: 60
      }}
      navbar={{
        width: 200,
        breakpoint: 'sm',
        collapsed: { mobile: !opened }
      }}
    >
      <AppShell.Header>
        <AppShell.Section>
          <Group justify="space-between">
            <Group ml="md" mt="xs" gap={'0.25rem'}>
              <Burger opened={opened} onClick={toggle} hiddenFrom="sm" />
              <img
                src="assets/logo-512.png"
                width="40"
                height="40"
                style={{
                  marginRight: '5px'
                }}
                alt="Knapsak logo"
              />
              <Title order={1}>Knapsak</Title>
            </Group>
            <RenderIf condition={computedColorScheme === 'dark'}>
              <ActionIcon
                onClick={scheme.toggleColorScheme}
                size="lg"
                mr="xl"
                mt="xs"
                variant="outline"
              >
                <MdOutlineLightMode />
              </ActionIcon>
            </RenderIf>
            <RenderIf condition={computedColorScheme === 'light'}>
              <ActionIcon
                onClick={scheme.toggleColorScheme}
                size="lg"
                mr="xl"
                mt="xs"
                variant="outline"
              >
                <MdOutlineDarkMode />
              </ActionIcon>
            </RenderIf>
          </Group>
        </AppShell.Section>
        <AppShell.Section></AppShell.Section>
      </AppShell.Header>
      <AppShell.Navbar>
        <AppShell.Section grow>
          <Sidebar />
        </AppShell.Section>
      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}
