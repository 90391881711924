import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { TripDetails } from './components';
import { getTripDetailsQuery } from './loader';

export const TripSummary = () => {
  const { tripId } = useParams();
  const { data: tripDetails } = useQuery(getTripDetailsQuery(tripId!));

  return <TripDetails trip={tripDetails!.my!.trips!.nodes!.at(0)!} />;
};
