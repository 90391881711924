import { RenderIf } from '@knapsak/shared/ui-render-if';
import { ProfileSearchResult } from '@knapsak/web/profiles/feature-search';
import { graphql, useKnapsakApi } from '@knapsak/web/shared/data-access';
import { ProblemBox } from '@knapsak/web/shared/feature-problems';
import { Button, Container, Group, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const TransferTripOwnershipMutation = graphql(`
  mutation TransferTripOwnership($input: TransferTripOwnershipInput!) {
    transferTripOwnership(input: $input) {
      ... on TransferTripOwnershipPayload {
        __typename
        tripId
      }

      ... on TransferTripOwnershipFailure {
        __typename
        problems {
          ... on IProblem {
            ...ProblemBox_ProblemFragment
          }
        }
      }
    }
  }
`);

export const TransferOwnershipModal = (
  props: ContextModalProps<{
    tripId: string;
    tripName: string;
    profile: ProfileSearchResult;
  }>
) => {
  const {
    id,
    context,
    innerProps: { tripId, tripName, profile }
  } = props;

  const { data, mutate, reset, isError } = useMutation(
    async (input: any) =>
      await knapsakApiClient.request(TransferTripOwnershipMutation, { input })
  );

  const close = () => {
    reset();
    context.closeModal(id);
  };

  const knapsakApiClient = useKnapsakApi();
  const queryClient = useQueryClient();

  if (
    data?.transferTripOwnership?.__typename === 'TransferTripOwnershipPayload'
  ) {
    queryClient.invalidateQueries(['trip', 'details', tripId]);

    showNotification({
      title: 'Transfer Ownership',
      message: `You have successfully transferred ownership of ${tripName} to ${profile.nickname}.`,
      color: 'green'
    });

    close();
  }

  return (
    <Container>
      <RenderIf condition={isError}>
        <Text c="red">Something went wrong. Please try again later.</Text>
      </RenderIf>
      <ProblemBox
        problems={
          data?.transferTripOwnership?.__typename ===
          'TransferTripOwnershipFailure'
            ? data?.transferTripOwnership.problems
            : []
        }
      />
      <Text>
        Are you sure you want to transfer ownership of <b>{tripName}</b> to{' '}
        <b>{profile.nickname}</b>?
      </Text>
      <Group mt="lg" justify="center">
        <Button onClick={() => mutate({ tripId, userId: profile.userId })}>
          Transfer
        </Button>
        <Button color="gray" onClick={close}>
          Cancel
        </Button>
      </Group>
    </Container>
  );
};
