import { RenderIf } from '@knapsak/shared/ui-render-if';
import { ProfileSearchResult } from '@knapsak/web/profiles/feature-search';
import { graphql, useKnapsakApi } from '@knapsak/web/shared/data-access';
import { ProblemBox } from '@knapsak/web/shared/feature-problems';
import { SimpleOops } from '@knapsak/web/shared/ui-errors';
import { Button, Container, Group, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const RescindTripInvitationMutation = graphql(`
  mutation RescindTripInvitation($input: RescindTripInvitationInput!) {
    rescindTripInvitation(input: $input) {
      __typename
      ... on RescindTripInvitationPayload {
        tripId
      }
      ... on RescindTripInvitationFailure {
        problems {
          ... on IProblem {
            ...ProblemBox_ProblemFragment
          }
        }
      }
    }
  }
`);

export const RescindInvitationModal = (
  props: ContextModalProps<{ tripId: string; profile: ProfileSearchResult }>
) => {
  const {
    id,
    context,
    innerProps: { tripId, profile }
  } = props;

  const knapsakApiClient = useKnapsakApi();
  const {
    data: result,
    mutate,
    reset,
    isError
  } = useMutation(
    async (input: any) =>
      await knapsakApiClient.request(RescindTripInvitationMutation, { input })
  );

  const queryClient = useQueryClient();

  const close = () => {
    reset();
    context.closeModal(id);
  };

  if (
    result?.rescindTripInvitation?.__typename === 'RescindTripInvitationPayload'
  ) {
    queryClient.invalidateQueries(['trip', 'details', tripId]);

    showNotification({
      title: 'Rescind Invitation',
      message: `You have successfully rescinded the invitation for ${profile.nickname}.`,
      color: 'green'
    });

    close();
  }

  return (
    <Container>
      <ProblemBox
        problems={
          result?.rescindTripInvitation?.__typename ==
          'RescindTripInvitationFailure'
            ? result?.rescindTripInvitation.problems
            : []
        }
      />
      <RenderIf condition={isError}>
        <SimpleOops />
      </RenderIf>
      <Text>
        Are you sure you want to rescind the invitation for {profile.nickname}?
      </Text>
      <Group mt="lg" justify="center">
        <Button
          color="red"
          onClick={() => {
            mutate({ tripId, userId: profile.userId });
          }}
        >
          Yes
        </Button>
        <Button color="gray" onClick={() => close()}>
          No
        </Button>
      </Group>
    </Container>
  );
};
