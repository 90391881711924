import { useAuth } from '@knapsak/web/auth/data-access';
import { ROUTES } from '@knapsak/web/navigation/util-routes';
import { TripInvitationList } from '@knapsak/web/trips/feature-trip-invitations';
import { Avatar, Box, Group, Menu, NavLink, Stack, Text } from '@mantine/core';
import { GiBackpack } from 'react-icons/gi';
import { HiOutlineMail } from 'react-icons/hi';
import { IoCompassOutline } from 'react-icons/io5';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Sections = [
  {
    label: 'Trips',
    icon: <IoCompassOutline size={24} />,
    route: ROUTES.MyTrips(),
    subSections: [
      { label: 'Upcoming', route: ROUTES.MyUpcomingTrips() },
      { label: 'Past', route: ROUTES.MyPastTrips() },
      { label: 'New Trip', route: ROUTES.NewTrip() }
    ]
  },
  {
    label: 'Gear',
    icon: <GiBackpack size={24} />,
    route: ROUTES.MyGearRoom(),
    subSections: [{ label: 'Gear Room', route: ROUTES.MyGearRoom() }]
  }
];

export function Sidebar() {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const getActiveSectionIndex = () => {
    const sectionIndex = Sections.findIndex((section) =>
      location.pathname.startsWith(section.route)
    );

    return sectionIndex;
  };

  return (
    <Stack justify="space-between" mih="100%">
      <Box>
        {Sections.map((section, idx) => (
          <NavLink
            key={section.label}
            label={section.label}
            leftSection={section.icon}
            active={idx === getActiveSectionIndex()}
            opened={idx === getActiveSectionIndex() ? true : undefined}
          >
            {section.subSections.map((subSection) => (
              <NavLink
                key={`${section.label}-${subSection.label}`}
                label={subSection.label}
                onClick={() => navigate(subSection.route)}
              />
            ))}
          </NavLink>
        ))}
      </Box>
      <Box>
        <Menu trigger="hover" position="right-start" withArrow>
          <Menu.Target>
            <NavLink
              label="Invitations"
              leftSection={<HiOutlineMail size={24} />}
            />
          </Menu.Target>
          <Menu.Dropdown>
            <TripInvitationList />
          </Menu.Dropdown>
        </Menu>
        <Group m="sm" wrap="nowrap">
          <Avatar radius={20} src={user?.picture} />
          <Stack gap="0" miw={0}>
            <Text truncate="end">{user?.name}</Text>
            <Link to="auth/logout">
              <Text>Sign Out</Text>
            </Link>
          </Stack>
        </Group>
      </Box>
    </Stack>
  );
}
