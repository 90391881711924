import { Gear } from '@knapsak/web/gear/models';
import { useState } from 'react';
import { EditableGearRow } from './EditableGearRow';
import { ReadOnlyGearRow } from './ReadOnlyGearRow';

type GearRowProps = {
  gear: Gear;
  onDelete: () => void;
  onSubmit: (gear: Gear, onComplete: () => void) => void;
};

export const GearRow = (props: GearRowProps) => {
  const { gear, onSubmit, onDelete } = props;
  const [isEditing, setIsEditing] = useState(false);
  const isNewGear = gear.intent === 'create';

  const submit = (updatedGear: Gear) => {
    onSubmit(updatedGear, () => setIsEditing(false));
  };

  const cancel = () => {
    if (isNewGear) onDelete();
    else setIsEditing(false);
  };

  if (isEditing || isNewGear)
    return (
      <EditableGearRow
        gear={gear}
        onSubmitClicked={submit}
        onCancelClicked={cancel}
      />
    );
  else
    return (
      <ReadOnlyGearRow
        gear={gear}
        onEditClicked={() => setIsEditing(true)}
        onDeleteClicked={onDelete}
      />
    );
};
