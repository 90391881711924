import { Colors } from '@knapsak/shared/ui-theme';
import { AuthProvider } from '@knapsak/web/auth/data-access';
import { generateColors } from '@mantine/colors-generator';
import { CSSVariablesResolver, MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MapProvider } from 'react-map-gl';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AppRoutes from './routes';
import Services from './services';

const router = createBrowserRouter(AppRoutes);

const queryClient = Services.make<QueryClient>('queryClient');

const resolver: CSSVariablesResolver = (theme) => ({
  variables: {},
  light: {
    '--mantine-color-gray': Colors.light.gray
  },
  dark: {
    '--mantine-color-gray': Colors.dark.gray
  }
});

export const App = () => {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          cssVariablesResolver={resolver}
          defaultColorScheme="auto"
          theme={{
            primaryColor: `primary`,
            colors: {
              primary: generateColors(Colors.primary)
            },
            headings: {
              fontFamily: 'Trebuchet MS, sans-serif'
            }
          }}
        >
          <ModalsProvider>
            <Notifications />
            <MapProvider>
              <RouterProvider router={router} />
            </MapProvider>
          </ModalsProvider>
        </MantineProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};
