import { RenderIf } from '@knapsak/shared/ui-render-if';
import { useAuth } from '@knapsak/web/auth/data-access';
import { ProfileSearchResult } from '@knapsak/web/profiles/feature-search';
import {
  FragmentType,
  getFragmentData,
  graphql
} from '@knapsak/web/shared/data-access';
import {
  ActionIcon,
  Avatar,
  Group,
  Indicator,
  Menu,
  SimpleGrid,
  Space,
  Table,
  Text,
  Title
} from '@mantine/core';
import { useModals } from '@mantine/modals';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useState } from 'react';
import { IoMdMore } from 'react-icons/io';
import { IoPersonAdd } from 'react-icons/io5';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import { RiDoorOpenLine } from 'react-icons/ri';

export const TripDetailsFragment = graphql(`
  fragment TripDetails on Trip {
    id
    name
    ownerUserId
    privacyLevel
    createdOnUtc
    startTimeUtc
    endTimeUtc
    members {
      userId
      profile {
        userId
        nickname
        picture
      }
      roles
    }
    invitations {
      toUserId
      recipient {
        userId
        nickname
        picture
      }
    }
  }
`);

interface Props {
  trip: FragmentType<typeof TripDetailsFragment>;
}

const dateTimeOptions: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
};

export function TripDetails(props: Props) {
  const [trip, setTrip] = useState(
    getFragmentData(TripDetailsFragment, props.trip)
  );
  const { user } = useAuth();

  const userId = user ? user['https://knapsak.io/uuid'] : undefined;
  const modals = useModals();

  const isOwner = trip.ownerUserId === userId;
  const isGroupMember =
    trip.members.filter((m) => m.userId === userId).length > 0;

  const properties = [
    { name: 'Privacy', value: trip.privacyLevel },
    {
      name: 'Created',
      value: new Date(trip.createdOnUtc).toLocaleString(
        'en-US',
        dateTimeOptions
      )
    },
    {
      name: 'Starting',
      value: new Date(trip.startTimeUtc).toLocaleString(
        'en-US',
        dateTimeOptions
      )
    },
    {
      name: 'Ending',
      value: new Date(trip.endTimeUtc).toLocaleString('en-US', dateTimeOptions)
    }
  ];

  return (
    <>
      <Group mt="lg" justify="space-between">
        <Title mr="sm" maw="75%">
          {trip.name}
        </Title>
        <Menu zIndex={10000}>
          <Menu.Target>
            <ActionIcon size="lg" color="black">
              <IoMdMore size="lg" />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <RenderIf condition={isGroupMember && trip.members.length > 1}>
              <Menu.Item
                leftSection={<RiDoorOpenLine />}
                color="red"
                onClick={() =>
                  modals.openContextModal('leaveTrip', {
                    title: 'Leave Trip',
                    innerProps: {
                      tripId: trip.id,
                      tripName: trip.name
                    },
                    size: 'lg',
                    centered: true
                  })
                }
              >
                Leave
              </Menu.Item>
            </RenderIf>
            <RenderIf condition={isOwner}>
              <Menu.Item
                leftSection={<MdEdit />}
                onClick={() =>
                  modals.openContextModal('renameTrip', {
                    title: 'Rename Trip',
                    innerProps: {
                      tripId: trip.id,
                      tripName: trip.name,
                      onSuccess: (tripId: string, newName: string) => {
                        setTrip((t) => ({ ...t, name: newName }));
                      }
                    },
                    size: 'xs',
                    centered: true
                  })
                }
              >
                Rename
              </Menu.Item>
            </RenderIf>
            <RenderIf condition={isOwner && trip.members.length === 1}>
              <Menu.Item
                color="red"
                leftSection={<MdDeleteForever />}
                onClick={() =>
                  modals.openContextModal('deleteTrip', {
                    title: 'Delete Trip',
                    innerProps: {
                      tripId: trip.id,
                      tripName: trip.name
                    },
                    size: 'lg',
                    centered: true
                  })
                }
              >
                Delete
              </Menu.Item>
            </RenderIf>
          </Menu.Dropdown>
        </Menu>
      </Group>
      <Table mt="lg">
        <tbody>
          {properties.map((property, idx) => (
            <tr key={idx}>
              <td>
                <b>{property.name}</b>
              </td>
              <td>{property.value}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Group mt="lg" justify="space-between" align="center">
        <Title order={3}>Group</Title>
        <ActionIcon
          color="dark"
          onClick={() =>
            modals.openContextModal('inviteUser', {
              title: 'Invite User',
              centered: true,
              size: 'xl',
              innerProps: {
                tripId: trip.id,
                tripName: trip.name
              }
            })
          }
        >
          <IoPersonAdd size="lg" />
        </ActionIcon>
      </Group>
      <SimpleGrid cols={5} mt="md" ml="lg">
        {trip.members?.map((member, idx) => (
          <Menu key={idx} zIndex={10000}>
            <Menu.Target>
              <Group justify="center">
                <Indicator
                  inline
                  label="Owner"
                  color="yellow"
                  position="bottom-center"
                  disabled={trip.ownerUserId !== member.userId}
                >
                  <Avatar radius={25} src={member.profile.picture} size="md" />
                </Indicator>
                <Text fz="xs" truncate="end">
                  {member.profile.nickname}
                </Text>
              </Group>
            </Menu.Target>
            <RenderIf condition={isOwner && member.userId !== userId}>
              <Menu.Dropdown>
                <Menu.Item
                  onClick={() =>
                    modals.openContextModal('assignRoles', {
                      title: 'Assign Roles',
                      innerProps: {
                        userId: member.userId,
                        userNickname: member.profile.nickname,
                        roles: member.roles,
                        tripId: trip.id
                      },
                      size: 'lg',
                      centered: true
                    })
                  }
                >
                  Assign Roles
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    modals.openContextModal('transferOwnership', {
                      title: 'Transfer Ownership',
                      innerProps: {
                        profile: member.profile as ProfileSearchResult,
                        tripId: trip.id,
                        tripName: trip.name
                      },
                      size: 'lg',
                      centered: true
                    })
                  }
                >
                  Transfer Ownership
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    modals.openContextModal('kickUser', {
                      title: 'Kick User',
                      innerProps: {
                        profile: member.profile as ProfileSearchResult,
                        tripId: trip.id,
                        tripName: trip.name
                      },
                      size: 'lg',
                      centered: true
                    })
                  }
                >
                  Kick Member
                </Menu.Item>
              </Menu.Dropdown>
            </RenderIf>
          </Menu>
        ))}
        {trip.invitations?.map((invitation, idx) => (
          <Menu key={idx}>
            <Menu.Target>
              <Group justify="center">
                <Indicator
                  inline
                  label="Invited"
                  color="grey"
                  position="bottom-center"
                >
                  <Avatar radius={25} src={invitation.recipient.picture} />
                </Indicator>
                <Text fz="xs" truncate="end">
                  {invitation.recipient.nickname}
                </Text>
              </Group>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={() =>
                  modals.openContextModal('rescindInvitation', {
                    title: 'Rescind Invitation',
                    innerProps: {
                      tripId: trip.id,
                      profile: invitation.recipient as ProfileSearchResult
                    },
                    centered: true,
                    size: 'lg'
                  })
                }
              >
                Rescind Invitation
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ))}
      </SimpleGrid>
      <Space mt="xl" />
    </>
  );
}
