import styled from '@emotion/styled';
import { Avatar, CloseButton, Grid, Text } from '@mantine/core';

interface Props {
  fullName: string;
  picture: string;
  nickname: string;
  onRemove: () => void;
}

export const InvitedUser = (props: Props) => {
  const { fullName, picture, nickname, onRemove } = props;

  return (
    <Container>
      <Grid align="center">
        <Grid.Col span={2}>
          <Avatar src={picture} />
        </Grid.Col>
        <Grid.Col span={9}>
          <Text>{fullName}</Text>
          <Text fz="sm" color="gray.6">
            {nickname}
          </Text>
        </Grid.Col>
        <Grid.Col span={1}>
          <CloseButton onClick={() => onRemove()} />
        </Grid.Col>
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid var(--mantine-color-gray-7);
  border-radius: 4px;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
`;
