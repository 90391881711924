import { GearFormProvider, useGearForm } from '@knapsak/web/gear/data-access';
import { Gear } from '@knapsak/web/gear/models';
import {
  ActionIcon,
  Grid,
  Group,
  NumberInput,
  TagsInput,
  TextInput
} from '@mantine/core';
import { hasLength, isInRange } from '@mantine/form';
import { MdCheck, MdClose } from 'react-icons/md';
import { WeightInput } from './WeightInput';

type EditableGearRowProps = {
  gear: Gear;
  onSubmitClicked: (updatedGear: Gear) => void;
  onCancelClicked: () => void;
};

const ColumnSpan = { base: 6, md: 2 };
const QuantityColumnSpan = { base: 6, md: 1 };
const TagsColumnSpan = { base: 6, md: 4 };
const WeightColumnSpan = { base: 6, md: 2 };
const ActionsColumnSpan = { base: 6, md: 1 };

export const EditableGearRow = (props: EditableGearRowProps) => {
  const { gear, onSubmitClicked, onCancelClicked } = props;

  const form = useGearForm({
    initialValues: gear,
    validate: {
      name: hasLength({ min: 1 }, 'Name is required.'),
      brand: hasLength({ min: 1 }, 'Brand is required.'),
      tags: hasLength({ max: 5 }, 'A maximum of 5 tags are allowed.'),
      weight: {
        value: isInRange(
          { min: 0 },
          'Weight must be greater than or equal to 0.'
        )
      }
    }
  });

  return (
    <GearFormProvider form={form}>
      <form
        onSubmit={form.onSubmit((values) => onSubmitClicked({ ...values }))}
      >
        <Grid gutter="sm" align="center">
          <Grid.Col span={6} hiddenFrom="md">
            Brand
          </Grid.Col>
          <Grid.Col span={ColumnSpan}>
            <TextInput {...form.getInputProps('brand')} />
          </Grid.Col>
          <Grid.Col span={6} hiddenFrom="md">
            Name
          </Grid.Col>
          <Grid.Col span={ColumnSpan}>
            <TextInput {...form.getInputProps('name')} />
          </Grid.Col>
          <Grid.Col span={6} hiddenFrom="md">
            Quantity
          </Grid.Col>
          <Grid.Col span={QuantityColumnSpan}>
            <NumberInput {...form.getInputProps('quantity')} />
          </Grid.Col>
          <Grid.Col span={6} hiddenFrom="md">
            Weight
          </Grid.Col>
          <Grid.Col span={WeightColumnSpan}>
            <WeightInput />
          </Grid.Col>
          <Grid.Col span={6} hiddenFrom="md">
            Tags
          </Grid.Col>
          <Grid.Col span={TagsColumnSpan}>
            <TagsInput
              styles={PillInputStyle}
              placeholder='Add tags separated by ","'
              {...form.getInputProps('tags')}
            />
          </Grid.Col>
          <Grid.Col span={6} hiddenFrom="md">
            Action
          </Grid.Col>
          <Grid.Col span={ActionsColumnSpan}>
            <Group gap="xs" justify="right">
              <ActionIcon type="submit" color="green">
                <MdCheck />
              </ActionIcon>
              <ActionIcon color="red" onClick={() => onCancelClicked()}>
                <MdClose />
              </ActionIcon>
            </Group>
          </Grid.Col>
        </Grid>
      </form>
    </GearFormProvider>
  );
};

export const PillInputStyle = {
  pill: {
    color: 'var(--mantine-color-white)',
    backgroundColor: 'var(--mantine-primary-color-filled)'
  }
};
