import { DocumentType, graphql } from '@knapsak/web/shared/data-access';
import { Avatar, Box, Group, Text } from '@mantine/core';

const SearchProfiles_ProfileFragment = graphql(`
  fragment SearchProfiles_ProfileFragment on Profile {
    nickname
    userId
    picture
    fullName
  }
`);

export type ProfileSearchResult = DocumentType<
  typeof SearchProfiles_ProfileFragment
>;

type Props = {
  profile: ProfileSearchResult;
};

export const SearchResult = (props: Props) => {
  const { profile } = props;
  return (
    <Group>
      <Avatar src={profile.picture} />
      <Box>
        <Text>{profile.fullName}</Text>
        <Text>{profile.nickname}</Text>
      </Box>
    </Group>
  );
};
