import { graphql, useKnapsakApi } from '@knapsak/web/shared/data-access';
import { Combobox, Loader, TextInput, useCombobox } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { ProfileSearchResult, SearchResult } from './SearchResult';

const SearchProfilesQuery = graphql(`
  query SearchProfiles($queryString: String!) {
    profiles {
      search(input: { query: $queryString, page: 0 }) {
        nodes {
          userId
          fullName
          ...SearchProfiles_ProfileFragment
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`);

interface Props {
  disabled: boolean;
  label?: string;
  error?: string;
  onProfileSelected: (profile: ProfileSearchResult) => void;
}

export const ProfileSearch = (props: Props) => {
  const { error, label, disabled, onProfileSelected } = props;
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  });
  const [queryString, setQueryString] = useState<string>('');
  const [debouncedQueryString] = useDebouncedValue(queryString, 500);
  const knapsakApiClient = useKnapsakApi();
  const { isLoading, data, refetch } = useInfiniteQuery({
    queryKey: ['profiles', 'search', queryString],
    queryFn: async (_) =>
      await knapsakApiClient.request(SearchProfilesQuery, {
        queryString
      }),
    getNextPageParam: (lastPage, pageParam: any) =>
      lastPage.profiles.search?.pageInfo.hasNextPage ? pageParam + 1 : null,
    enabled: false
  });

  const foundProfiles =
    data?.pages.flatMap(
      (p) =>
        p.profiles.search?.nodes?.map((s) => s as ProfileSearchResult) ?? []
    ) ?? [];

  useEffect(() => {
    if (debouncedQueryString && debouncedQueryString.trim().length > 0) {
      refetch();
    }
  }, [debouncedQueryString, refetch]);

  const selectUser = (profile: ProfileSearchResult) => {
    onProfileSelected(profile);
    combobox.closeDropdown();
  };

  return (
    <Combobox store={combobox}>
      <Combobox.Target>
        <TextInput
          label={label}
          error={error}
          disabled={disabled}
          placeholder="Search by username, screen name, or email."
          onChange={(v) => {
            setQueryString(v.target.value);
            combobox.resetSelectedOption();
            combobox.openDropdown();
          }}
          rightSection={
            isLoading && debouncedQueryString.length > 0 && <Loader size={18} />
          }
        />
      </Combobox.Target>

      <Combobox.Dropdown hidden={!queryString.length}>
        <Combobox.Options>
          {foundProfiles.map((profile) => (
            <Combobox.Option
              key={profile.userId}
              value={profile.userId}
              onClick={() => selectUser(profile)}
            >
              <SearchResult profile={profile} />
            </Combobox.Option>
          ))}
          {foundProfiles.length === 0 && (
            <Combobox.Empty>No users found.</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
