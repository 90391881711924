import styled from '@emotion/styled';
import { RenderIf } from '@knapsak/shared/ui-render-if';
import { Gear } from '@knapsak/web/gear/models';
import { Center, Container, Grid, Skeleton, Text } from '@mantine/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GearRow } from '../GearRow';
import { GearSortOption, Header } from './Header';

type GearListProps = {
  gear: Gear[];
  isLoading: boolean;
  hasMore: boolean;
  sort: GearSortOption;
  onDeleteGear: (gear: Gear) => void;
  onSubmitGear: (gear: Gear, onSuccess: () => void) => void;
  onLoadMore: () => void;
  onSortChanged: (sort: GearSortOption) => void;
};

export const GearList = (props: GearListProps) => {
  const {
    gear,
    hasMore,
    isLoading,
    sort,
    onSubmitGear,
    onDeleteGear,
    onLoadMore,
    onSortChanged
  } = props;

  return (
    <Grid gutter={0}>
      <Grid.Col visibleFrom="md">
        <Header sort={sort} onSortChanged={onSortChanged} />
      </Grid.Col>
      <RenderIf condition={isLoading}>
        {Array(15)
          .fill(0)
          .map((_, idx) => (
            <GearRowWrapper key={`skeleton-${idx}`}>
              <Center>
                <Skeleton width="100%" height="40px" m="sm" radius="10px" />
              </Center>
            </GearRowWrapper>
          ))}
      </RenderIf>
      <RenderIf condition={gear.length > 0 && !isLoading}>
        <div
          id="gear-list-scrollarea"
          style={{ width: '100%', overflow: 'auto' }}
        >
          <InfiniteScroll
            dataLength={gear.length}
            hasMore={hasMore}
            next={onLoadMore}
            scrollableTarget="gear-list-scrollarea"
            loader={<Center>Loading...</Center>}
            endMessage={
              <Center>
                <Text size="xl" mt="sm" mb="sm">
                  You have reached the end of your gear list!{' '}
                  <span role="img" aria-label="done">
                    🎉
                  </span>
                </Text>
              </Center>
            }
          >
            {gear.map((gear) => (
              <GearRowWrapper key={`gear-${gear.id}`}>
                <GearRow
                  gear={gear}
                  onDelete={() => onDeleteGear(gear)}
                  onSubmit={onSubmitGear}
                />
              </GearRowWrapper>
            ))}
          </InfiniteScroll>
        </div>
      </RenderIf>
      <RenderIf condition={gear.length === 0 && !isLoading}>
        <Container p="lg">
          <Center>Your gear room is empty...</Center>
          <Center>
            <Text c="primary.3">Click "Add Gear" to start filling it up!</Text>
          </Center>
        </Container>
      </RenderIf>
    </Grid>
  );
};

const GearRowWrapper = styled(Grid.Col)`
  .mantine-Grid-inner {
    padding: var(--mantine-spacing-xs);
  }

  &:nth-of-type(2n) {
    background-color: var(--mantine-color-gray);
  }
`;
