import { Pill } from '@mantine/core';

type GearTagProps = {
  name: string;
  onRemove?: () => void;
  withRemoveButton?: boolean;
};

export const GearTag = (props: GearTagProps) => {
  const { name, onRemove, withRemoveButton } = props;

  return (
    <Pill
      styles={PillStyle}
      radius="xl"
      withRemoveButton={withRemoveButton ?? false}
      onRemove={onRemove}
    >
      {name}
    </Pill>
  );
};

const PillStyle = {
  root: {
    color: 'var(--mantine-color-white)',
    backgroundColor: 'var(--mantine-primary-color-filled)'
  }
};
