import { graphql, useKnapsakApi } from '@knapsak/web/shared/data-access';
import { Button } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const DeclineTripInvitationMutation = graphql(`
  mutation DeclineTripInvitation($input: DeclineTripInvitationInput!) {
    declineTripInvitation(input: $input) {
      __typename
      ... on DeclineTripInvitationPayload {
        __typename
        tripId
      }

      ... on DeclineTripInvitationFailure {
        __typename
        problems {
          ... on IProblem {
            message
          }
        }
      }
    }
  }
`);

interface Props {
  tripId: string;
  tripName: string;
}

export const DeclineInvitationButton = (props: Props) => {
  const { tripId, tripName } = props;
  const queryClient = useQueryClient();
  const knapsakApiClient = useKnapsakApi();
  const {
    data: declineResult,
    mutate: declineInvitation,
    isError: isErrorDeclining,
    reset: resetDecline
  } = useMutation(
    async (input: any) =>
      await knapsakApiClient.request(DeclineTripInvitationMutation, { input })
  );

  if (
    declineResult?.declineTripInvitation?.__typename ===
    'DeclineTripInvitationPayload'
  ) {
    showNotification({
      title: 'Decline Invitation',
      message: `You have declined to join ${tripName}.`,
      color: 'green'
    });

    queryClient.invalidateQueries(['my', 'tripInvitations']);
  } else if (
    declineResult?.declineTripInvitation?.__typename ===
      'DeclineTripInvitationFailure' ||
    isErrorDeclining
  ) {
    showNotification({
      title: 'Decline Invitation',
      message: 'Something went wrong. Please try again later.',
      color: 'red'
    });

    resetDecline();
  }

  return (
    <Button
      color="red"
      onClick={() => {
        declineInvitation({
          tripId: tripId
        });
      }}
    >
      Decline
    </Button>
  );
};
