import { useAuth } from '@knapsak/web/auth/data-access';
import { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

export const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const { isAuthenticated, login } = useAuth();
  const defaultRoute = '/';
  const redirect = searchParams.get('redirect');

  useEffect(() => {
    if (!isAuthenticated) {
      login(redirect ?? defaultRoute);
    }
  }, [login, isAuthenticated, redirect, defaultRoute]);

  if (!isAuthenticated) {
    return <div>Redirecting to login...</div>;
  }

  return (
    <>
      <div>You are already logged in.</div>
      <div>Redirecting you to the previous page.</div>
      <Navigate to={redirect ?? defaultRoute} />
    </>
  );
};
