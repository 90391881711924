import { Auth0Client, User } from '@auth0/auth0-spa-js';
import Services from '@knapsak/shared/util-service-container';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

export type AuthContextType = {
  user: User | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (returnTo: string) => Promise<void>;
  logout: () => Promise<void>;
  handleRedirectCallback: () => Promise<string>;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = (props: PropsWithChildren) => {
  const { children } = props;

  const auth0Client = Services.make<Auth0Client>('auth0Client');
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const login = async (returnTo: string) => {
    await auth0Client.loginWithRedirect({
      appState: { returnTo: returnTo }
    });
  };

  const logout = async () => {
    await auth0Client.logout();
  };

  const handleRedirectCallback = useCallback(async () => {
    const callbackResult = await auth0Client.handleRedirectCallback();
    setIsAuthenticated(await auth0Client.isAuthenticated());
    setUser(await auth0Client.getUser());
    return callbackResult.appState.returnTo;
  }, []);

  useEffect(() => {
    const load = async () => {
      await auth0Client.checkSession();
      setIsAuthenticated(await auth0Client.isAuthenticated());
      setUser(await auth0Client.getUser());
      setIsLoading(false);
    };

    load();
  }, [auth0Client]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isLoading,
        login,
        logout,
        handleRedirectCallback
      }}
    >
      {!isLoading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
