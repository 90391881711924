import {
  AuthenticatedLayout,
  ProtectedRoute
} from '@knapsak/web/layout/feature-shell';
import { NotFoundPage } from '@knapsak/web/misc/feature-not-found';
import { SimpleOops } from '@knapsak/web/shared/ui-errors';
import { PrivateRoutes } from './private';
import { PublicRoutes } from './public';

const AppRoutes = [
  {
    path: '/',
    errorElement: <SimpleOops />,
    children: [
      ...PublicRoutes,
      {
        element: <AuthenticatedLayout />,
        children: [
          {
            element: <ProtectedRoute />,
            children: [...PrivateRoutes]
          }
        ]
      },
      { path: '*', element: <NotFoundPage /> }
    ]
  }
];

export default AppRoutes;
