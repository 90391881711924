import { RenderIf } from '@knapsak/shared/ui-render-if';
import { graphql, useKnapsakApi } from '@knapsak/web/shared/data-access';
import { Button, Checkbox, Container, Group, Space, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

const availableRoles = ['Administrator'];

const AssignTripMemberRoles = graphql(`
  mutation AssignTripMemberRoles($input: AssignTripMemberRolesInput!) {
    assignTripMemberRoles(input: $input) {
      ... on AssignTripMemberRolesPayload {
        __typename
      }
      ... on AssignTripMemberRolesFailure {
        __typename
        problems {
          ... on IProblem {
            message
          }
        }
      }
    }
  }
`);

export const AssignRolesModal = (
  props: ContextModalProps<{
    tripId: string;
    userId: string;
    userNickname: string;
    roles: string[];
  }>
) => {
  const {
    id,
    context,
    innerProps: { tripId, userId, userNickname, roles }
  } = props;

  const knapsakApiClient = useKnapsakApi();

  const [selectedRoles, setSelectedRoles] = useState<string[]>(roles);
  const {
    mutate,
    data: result,
    reset,
    isError
  } = useMutation(
    async (input: any) =>
      await knapsakApiClient.request(AssignTripMemberRoles, { input })
  );

  const queryClient = useQueryClient();

  const close = () => {
    reset();
    context.closeModal(id);
  };

  if (
    result?.assignTripMemberRoles?.__typename === 'AssignTripMemberRolesPayload'
  ) {
    queryClient.invalidateQueries(['trip', 'details', tripId]);

    showNotification({
      title: 'Assign Roles',
      message: `Roles have been successfully assigned to ${userNickname}.`,
      color: 'green'
    });

    close();
  }

  return (
    <Container>
      <RenderIf condition={isError}>
        <Text c="red">
          There was a problem assigning roles. Please try again later.
        </Text>
      </RenderIf>
      <Text>Select all roles that apply to {userNickname}.</Text>
      <Space h="md" />
      {availableRoles.map((r) => {
        return (
          <Checkbox
            key={r}
            label={r}
            checked={selectedRoles.filter((v) => v === r).length > 0}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRoles([...selectedRoles, r]);
              } else {
                setSelectedRoles(selectedRoles.filter((v) => v !== r));
              }
            }}
          />
        );
      })}
      <Group justify="center" mt="md">
        <Button
          onClick={() =>
            mutate({
              tripId,
              userId: userId,
              roles: selectedRoles
            })
          }
        >
          Save
        </Button>
      </Group>
    </Container>
  );
};
