import { Gear, Weight } from '@knapsak/web/gear/models';
import { randomId } from '@mantine/hooks';

export const createNewGear = () =>
  ({
    id: randomId(),
    intent: 'create',
    name: '',
    brand: '',
    tags: [] as string[],
    weight: { value: 0, unit: 'g' } as Weight,
    quantity: 1
  } as Gear);
