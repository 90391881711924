import { RenderIf } from '@knapsak/shared/ui-render-if';
import {
  ProfileSearch,
  ProfileSearchResult
} from '@knapsak/web/profiles/feature-search';
import { graphql, useKnapsakApi } from '@knapsak/web/shared/data-access';
import { ProblemBox } from '@knapsak/web/shared/feature-problems';
import { SimpleOops } from '@knapsak/web/shared/ui-errors';
import { Button, Container, Flex, Group, Space, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals/lib/context';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { BiUserPlus } from 'react-icons/bi';
import { SelectedUser } from './SelectedUser';

const SendTripInvitationMutation = graphql(`
  mutation SendTripInvitation($input: SendTripInvitationInput!) {
    sendTripInvitation(input: $input) {
      ... on SendTripInvitationPayload {
        __typename
        tripId
      }

      ... on SendTripInvitationFailure {
        __typename
        problems {
          ...ProblemBox_ProblemFragment
        }
      }
    }
  }
`);

export const Icon = () => (
  <Flex pr="xs" align="center">
    <BiUserPlus size="32px" />
  </Flex>
);

export const InviteUserModal = (
  props: ContextModalProps<{ tripId: string; tripName: string }>
) => {
  const {
    id,
    context,
    innerProps: { tripId, tripName }
  } = props;
  const [selectedProfile, setSelectedProfile] =
    useState<ProfileSearchResult | null>(null);
  const knapsakApiClient = useKnapsakApi();
  const queryClient = useQueryClient();

  const {
    data: inviteResult,
    mutate,
    reset,
    isError
  } = useMutation(
    async (input: any) =>
      await knapsakApiClient.request(SendTripInvitationMutation, { input }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['trip', 'details', tripId]);

        showNotification({
          title: 'Invite User',
          message: `${selectedProfile?.nickname} was invited to join the group.`,
          color: 'green'
        });

        close();
      }
    }
  );

  const close = () => {
    reset();
    setSelectedProfile(null);
    context.closeModal(id);
  };

  return (
    <Container p="md">
      <ProblemBox
        problems={
          inviteResult?.sendTripInvitation?.__typename ===
          'SendTripInvitationFailure'
            ? inviteResult.sendTripInvitation.problems
            : []
        }
      />
      <RenderIf condition={isError}>
        <SimpleOops />
      </RenderIf>

      <Text>A notification will be sent to the invited user.</Text>
      <Text mt="md">
        Once accepted, they will be allowed to collaborate on <b>{tripName}</b>,
        save it on their mobile device, and receive trip notifications.
      </Text>
      <Space h="lg" />
      <RenderIf condition={selectedProfile === null}>
        <ProfileSearch
          disabled={selectedProfile !== null}
          onProfileSelected={(profile) => setSelectedProfile(profile)}
        />
      </RenderIf>
      <RenderIf condition={selectedProfile !== null}>
        <Space h="xl" />
        <Flex direction="column" align="center">
          <SelectedUser
            profile={selectedProfile!}
            onRemove={() => setSelectedProfile(null)}
          />
        </Flex>
      </RenderIf>
      <Group mt="xl" justify="center">
        <Button
          type="submit"
          name="intent"
          value="invite-user"
          disabled={!selectedProfile}
          onClick={() =>
            mutate({ tripId: tripId, userId: selectedProfile?.userId })
          }
        >
          Send Invitation
        </Button>
      </Group>
    </Container>
  );
};
