import { getFragmentData } from '@knapsak/web/shared/data-access';
import { Grid } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {
  AssignRolesModal,
  DeleteTripModal,
  InviteUserModal,
  KickUserModal,
  LeaveTripModal,
  RenameTripModal,
  RescindInvitationModal,
  TransferOwnershipModal,
  TripDetails,
  TripDetailsFragment,
  TripMap
} from './components';
import { getTripDetailsQuery } from './loader';

export const TripDetailsPage = () => {
  const { tripId } = useParams();
  const { data } = useQuery(getTripDetailsQuery(tripId!));

  const trip = getFragmentData(
    TripDetailsFragment,
    data?.my?.trips?.nodes?.at(0)
  );

  if (!trip) return <div>Not Found</div>;

  return (
    <ModalsProvider modals={modals}>
      <Grid h="100vh" columns={14}>
        <Grid.Col span={{ base: 6, lg: 3 }} pl="lg" pr="lg" h="100vh">
          <TripDetails trip={data!.my.trips!.nodes!.at(0)!} />
        </Grid.Col>
        <Grid.Col span={{ base: 8, lg: 11 }} h="100vh">
          <TripMap />
        </Grid.Col>
      </Grid>
    </ModalsProvider>
  );
};

const modals = {
  assignRoles: AssignRolesModal,
  leaveTrip: LeaveTripModal,
  inviteUser: InviteUserModal,
  rescindInvitation: RescindInvitationModal,
  transferOwnership: TransferOwnershipModal,
  kickUser: KickUserModal,
  deleteTrip: DeleteTripModal,
  renameTrip: RenameTripModal
};

declare module '@mantine/modals' {
  export interface MantineModalsOverride {
    modals: typeof modals;
  }
}
