import { Gear, WeightUnit } from '@knapsak/web/gear/models';
import { Gear as GqlGear, UnitOfWeight } from '@knapsak/web/shared/data-access';

export const fromApi = (gear: GqlGear) => {
  let unit = undefined;

  switch (gear.weight.unit) {
    case UnitOfWeight.Grams:
      unit = 'g';
      break;
    case UnitOfWeight.Ounces:
      unit = 'oz';
      break;
    case UnitOfWeight.Pounds:
      unit = 'lb';
      break;
  }

  return {
    id: gear.gearId,
    intent: 'none',
    name: gear.name,
    brand: gear.brand,
    tags: gear.tags,
    weight: {
      value: gear.weight.value,
      unit: unit
    },
    quantity: gear.quantity
  } as Gear;
};

export const toUnitOfWeight = (unit: WeightUnit) => {
  switch (unit) {
    case 'g':
      return UnitOfWeight.Grams;
    case 'oz':
      return UnitOfWeight.Ounces;
    case 'lb':
      return UnitOfWeight.Pounds;
  }
};
