import Map, { NavigationControl } from 'react-map-gl';

export const TripMap = () => {
  return (
    <Map
      id="tripMap"
      style={{ minWidth: '100%', minHeight: '100%' }}
      mapStyle="mapbox://styles/dead1ock/clfznctig001901mzphe0scm0"
      mapboxAccessToken="pk.eyJ1IjoiZGVhZDFvY2siLCJhIjoiY2preDF3MHFzMDQyODN2bXQ5dG52dG1jcSJ9.AI3tJ2sQSCiz9uYDpWUnYw"
    >
      <NavigationControl />
    </Map>
  );
};
