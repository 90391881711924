// This file can be replaced during build by using the `fileReplacements` array.
// When building for development, this file is replaced with `environment.dev.ts`.

export const environment = {
  production: false,
  env: 'dev',
  auth: {
    domain: 'knapsak-dev.us.auth0.com',
    clientId: 'I6oXZMA6XHWcDIf2NFRv0lfICVqTgQKR',
    redirectUri: 'https://dev.knapsak.io/auth/callback',
    audience: 'https://api.knapsak.io',
    scope: 'profile'
  },
  knapsakApi: {
    baseUrl: 'https://api.dev.knapsak.io/graphql/'
  }
};
