import { ProfileSearchResult } from '@knapsak/web/profiles/feature-search';
import { Avatar, Box, CloseButton, Group, Text } from '@mantine/core';

interface Props {
  profile: ProfileSearchResult;
  onRemove: () => void;
}

export const SelectedUser = (props: Props) => {
  const { profile, onRemove } = props;

  return (
    <Group>
      <Avatar src={profile.picture} />
      <Box>
        <Text>{profile.fullName}</Text>
        <Text>{profile.nickname}</Text>
      </Box>
      <CloseButton onClick={onRemove} />
    </Group>
  );
};
