import { graphql, useKnapsakApi } from '@knapsak/web/shared/data-access';
import { Button } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const AcceptTripInvitationMutation = graphql(`
  mutation AcceptTripInvitation($input: AcceptTripInvitationInput!) {
    acceptTripInvitation(input: $input) {
      __typename
      ... on AcceptTripInvitationPayload {
        tripId
      }

      ... on AcceptTripInvitationFailure {
        problems {
          ... on IProblem {
            message
          }
        }
      }
    }
  }
`);

interface Props {
  tripId: string;
  tripName: string;
}

export const AcceptInvitationButton = (props: Props) => {
  const { tripId, tripName } = props;
  const queryClient = useQueryClient();
  const knapsakApiClient = useKnapsakApi();

  const {
    data: acceptResult,
    mutate: acceptInvitation,
    isError: isErrorAccepting,
    reset: resetAccept
  } = useMutation(
    async () =>
      await knapsakApiClient.request(AcceptTripInvitationMutation, {
        input: { tripId }
      })
  );

  if (
    acceptResult?.acceptTripInvitation?.__typename ===
    'AcceptTripInvitationPayload'
  ) {
    showNotification({
      title: 'Accept Invitation',
      message: `You have joined ${tripName}.`,
      color: 'green'
    });

    queryClient.invalidateQueries(['my', 'tripInvitations']);
    queryClient.invalidateQueries(['my', 'trips']);
  } else if (
    acceptResult?.acceptTripInvitation?.__typename ===
      'AcceptTripInvitationFailure' ||
    isErrorAccepting
  ) {
    showNotification({
      title: 'Accept Invitation',
      message: 'Something went wrong. Please try again later.',
      color: 'red'
    });

    resetAccept();
  }

  return (
    <Button
      onClick={() => {
        acceptInvitation();
      }}
    >
      Accept
    </Button>
  );
};
