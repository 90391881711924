import { Auth0Client } from '@auth0/auth0-spa-js';
import Services from '@knapsak/shared/util-service-container';
import { QueryClient, QueryClientConfig } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { environment } from '../environments/environment';

Services.singleton('knapsakApiClient', (container) => {
  const authClient = container.make<Auth0Client>('auth0Client');

  return new GraphQLClient(environment.knapsakApi.baseUrl ?? '', {
    requestMiddleware: async (request) => {
      let headers = request.headers;

      await authClient.checkSession();

      if (await authClient.isAuthenticated()) {
        headers = {
          ...request.headers,
          Authorization: `Bearer ${await authClient.getTokenSilently()}`
        };
      }

      return {
        ...request,
        headers
      };
    },
    responseMiddleware: async (response: any) => {
      if (response instanceof Error) {
        const json = JSON.parse(JSON.stringify(response));

        const authError = json.response.errors.filter(
          (e: any) => e.extensions?.code === 'AUTH_NOT_AUTHORIZED'
        );

        if (authError.length > 0) {
          console.log('Not authorized, redirecting to login');
          await authClient.loginWithRedirect({
            appState: { returnTo: window.location.pathname }
          });
        }
      }

      return response;
    }
  });
});

Services.singleton(
  'queryClient',
  () =>
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: false
        }
      }
    } as QueryClientConfig)
);

Services.singleton(
  'auth0Client',
  () =>
    new Auth0Client({
      domain: environment.auth.domain,
      clientId: environment.auth.clientId,
      authorizationParams: {
        audience: environment.auth.audience,
        scope: environment.auth.scope,
        redirect_uri: environment.auth.redirectUri
      }
    })
);

export default Services;
