import Services from '@knapsak/shared/util-service-container';
import { QueryClient, QueryFunctionContext } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { MyTripSummaries } from './MyTripsPage';

export const loader = async (past: boolean) => {
  const query = getMyTripSummariesQuery(past);
  const queryClient = Services.make<QueryClient>('queryClient');
  return await queryClient.fetchInfiniteQuery(query);
};

const getMyTripSummaries = async (after: string | null, past: boolean) => {
  const knapsakApiClient = Services.make<GraphQLClient>('knapsakApiClient');
  return await knapsakApiClient.request(MyTripSummaries, {
    first: 10,
    after: after,
    filter: past
      ? { startTime: { lt: new Date() } }
      : { startTime: { gt: new Date() } },
    sort: past ? { startTime: 'DESC' as any } : { startTime: 'ASC' as any }
  });
};

export const getMyTripSummariesQuery = (past: boolean) => ({
  queryKey: ['my', 'trips', past],
  queryFn: ({ pageParam }: QueryFunctionContext) =>
    getMyTripSummaries(pageParam, past),
  getNextPageParam: (lastPage: any) =>
    lastPage?.my.trips?.pageInfo.hasNextPage
      ? lastPage?.my.trips?.pageInfo.endCursor
      : null,
  staleTime: 2 * 60 * 1000
});
