export const Colors = {
  primary: '#333366', // 240c54, 7C5BBC, 845EC2, 8d67d6, 443169, 333366,
  deepKoamaru: '#333366',

  light: {
    gray: '#F1F3F5'
  },
  dark: {
    gray: '#212529'
  }
};
