import styled from '@emotion/styled';
import { ROUTES } from '@knapsak/web/navigation/util-routes';
import { graphql } from '@knapsak/web/shared/data-access';
import { Button, Divider, Group, Title } from '@mantine/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { TripList } from './components';
import { getMyTripSummariesQuery } from './loader';

export const MyTripSummaries = graphql(`
  query MyTripSummaries(
    $first: Int!
    $after: String
    $filter: TripFilterInput
    $sort: [TripSortInput!]
  ) {
    my {
      trips(first: $first, after: $after, where: $filter, order: $sort) {
        nodes {
          ...TripSummary
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`);

interface Props {
  past: boolean;
}

export const MyTripsPage = (props: Props) => {
  const { past } = props;
  const navigate = useNavigate();
  const { data, hasNextPage, fetchNextPage } = useInfiniteQuery({
    ...getMyTripSummariesQuery(past)
  });

  const trips = data?.pages.flatMap((page) => page.my.trips?.nodes ?? []) ?? [];

  return (
    <>
      <TitleContainer>
        <Group gap={40}>
          <Title order={1}>My Trips</Title>
          <Group>
            <Button
              variant={past ? 'subtle' : 'outline'}
              onClick={() => navigate(ROUTES.MyUpcomingTrips())}
            >
              Upcoming
            </Button>
            <Button
              variant={past ? 'outline' : 'subtle'}
              onClick={() => navigate(ROUTES.MyPastTrips())}
            >
              Past
            </Button>
          </Group>
        </Group>
        <Button onClick={() => navigate(ROUTES.NewTrip())}>New Trip</Button>
      </TitleContainer>
      <Divider size={'md'} />
      <Section>
        <TripList
          trips={trips}
          emptyMessage={`You have no ${past ? 'past' : 'upcoming'} trips.`}
        />
        {hasNextPage && (
          <Button variant="outline" onClick={() => fetchNextPage()}>
            Show More
          </Button>
        )}
      </Section>
    </>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 1em;
  justify-content: space-between;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 2.5em;
  align-items: center;
`;
