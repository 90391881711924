import styled from '@emotion/styled';
import { FragmentType } from '@knapsak/web/shared/data-access';
import { Table } from '@mantine/core';
import TripListItem, { TripSummaryFragment } from './TripListItem';

const Header = styled.tr`
  font-weight: bold;
  color: #f2f2f2;
  background-color: #1f1f1f;
`;

const EmptyRow = styled.td`
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: #282828;
  text-align: center;
`;

interface Props {
  trips: FragmentType<typeof TripSummaryFragment>[];
  emptyMessage?: string;
}

export default function TripList(props: Props) {
  const { trips, emptyMessage } = props;

  return (
    <Table>
      <thead>
        <Header>
          <td></td>
          <td>Date</td>
          <td>Name</td>
          <td>Duration</td>
          <td>Group</td>
        </Header>
      </thead>
      <tbody>
        {trips.length === 0 && (
          <EmptyRow colSpan={5}>
            {emptyMessage ?? 'There are no trips.'}
          </EmptyRow>
        )}
        {trips.map((trip, idx) => (
          <TripListItem key={idx} trip={trip} />
        ))}
      </tbody>
    </Table>
  );
}
