import { Weight, WeightUnit } from '@knapsak/web/gear/models';

export const convertWeight = (weight: Weight, to: WeightUnit) => {
  switch (to) {
    case 'g':
      return toGrams(weight);
    case 'oz':
      return toOunces(weight);
    case 'lb':
      return toPounds(weight);
  }
};

const toGrams = (weight: Weight): Weight => {
  switch (weight.unit) {
    case 'g':
      return weight;
    case 'oz':
      return { value: weight.value * 28.3495, unit: 'g' };
    case 'lb':
      return { value: weight.value * 453.592, unit: 'g' };
  }
};

const toOunces = (weight: Weight): Weight => {
  switch (weight.unit) {
    case 'g':
      return { value: weight.value / 28.3495, unit: 'oz' };
    case 'oz':
      return weight;
    case 'lb':
      return { value: weight.value * 16, unit: 'oz' };
  }
};

const toPounds = (weight: Weight): Weight => {
  switch (weight.unit) {
    case 'g':
      return { value: weight.value / 453.592, unit: 'lb' };
    case 'oz':
      return { value: weight.value / 16, unit: 'lb' };
    case 'lb':
      return weight;
  }
};
