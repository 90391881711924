/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation AddGear($input: AddGearInput!) {\n    addGear(input: $input) {\n      ... on AddGearFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n      ... on AddGearPayload {\n        __typename\n        gear {\n          id\n          userId\n          name\n          brand\n          tags\n          quantity\n          weight {\n            value\n            unit\n          }\n          createdOnUtc\n          updatedOnUtc\n        }\n      }\n    }\n  }\n": types.AddGearDocument,
    "\n  mutation EditGearInfo($input: EditGearInfoInput!) {\n    editGearInfo(input: $input) {\n      ... on EditGearInfoFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n      ... on EditGearInfoPayload {\n        __typename\n        gear {\n          id\n          userId\n          name\n          brand\n          tags\n          quantity\n          weight {\n            value\n            unit\n          }\n          createdOnUtc\n          updatedOnUtc\n        }\n      }\n    }\n  }\n": types.EditGearInfoDocument,
    "\n  mutation RemoveGear($gearId: UUID!) {\n    removeGear(input: { gearId: $gearId }) {\n      ... on RemoveGearFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n      ... on RemoveGearPayload {\n        __typename\n        gearId\n      }\n    }\n  }\n": types.RemoveGearDocument,
    "\n  query MyGear(\n    $first: Int!\n    $cursor: String\n    $filter: GearFilterInput\n    $sort: [GearSortInput!]\n    $search: String\n  ) {\n    my {\n      gearRoom {\n        gear(\n          search: $search\n          first: $first\n          after: $cursor\n          where: $filter\n          order: $sort\n        ) {\n          nodes {\n            id\n            name\n            brand\n            quantity\n            tags\n            userId\n            weight {\n              value\n              unit\n            }\n            createdOnUtc\n            updatedOnUtc\n          }\n          totalCount\n          pageInfo {\n            hasPreviousPage\n            hasNextPage\n            startCursor\n            endCursor\n          }\n        }\n      }\n    }\n  }\n": types.MyGearDocument,
    "\n  query MyGearRoomTags {\n    my {\n      gearRoom {\n        tags {\n          totalCount\n          nodes\n        }\n      }\n    }\n  }\n": types.MyGearRoomTagsDocument,
    "\n  query SearchProfiles($queryString: String!) {\n    searchProfiles(input: { query: $queryString, page: 0 }) {\n      nodes {\n        userId\n        fullName\n        ...SearchProfiles_ProfileFragment\n      }\n      pageInfo {\n        hasNextPage\n      }\n    }\n  }\n": types.SearchProfilesDocument,
    "\n  fragment SearchProfiles_ProfileFragment on Profile {\n    nickname\n    userId\n    picture\n    fullName\n  }\n": types.SearchProfiles_ProfileFragmentFragmentDoc,
    "\n  fragment ProblemBox_ProblemFragment on IProblem {\n    __typename\n    message\n  }\n": types.ProblemBox_ProblemFragmentFragmentDoc,
    "\n  mutation CreateTrip($input: CreateTripInput!) {\n    createTrip(input: $input) {\n      ... on CreateTripPayload {\n        __typename\n        tripId\n      }\n\n      ... on CreateTripFailure {\n        __typename\n        problems {\n          ... on ValidationProblem {\n            field\n            message\n          }\n\n          ... on UserAlreadyInGroupProblem {\n            message\n          }\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n            message\n          }\n        }\n      }\n    }\n  }\n": types.CreateTripDocument,
    "\n  query MyTripSummaries(\n    $first: Int!\n    $after: String\n    $filter: TripFilterInput\n    $sort: [TripSortInput!]\n  ) {\n    my {\n      trips(first: $first, after: $after, where: $filter, order: $sort) {\n        nodes {\n          ...TripSummary\n        }\n        totalCount\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n": types.MyTripSummariesDocument,
    "\n  fragment TripSummary on Trip {\n    id\n    privacyLevel\n    name\n    startTimeUtc\n    endTimeUtc\n    members {\n      profile {\n        nickname\n        picture\n      }\n      userId\n      roles\n    }\n  }\n": types.TripSummaryFragmentDoc,
    "\n  mutation AssignTripMemberRoles($input: AssignTripMemberRolesInput!) {\n    assignTripMemberRoles(input: $input) {\n      ... on AssignTripMemberRolesPayload {\n        __typename\n      }\n      ... on AssignTripMemberRolesFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n    }\n  }\n": types.AssignTripMemberRolesDocument,
    "\n  mutation DeleteTrip($input: DeleteTripInput!) {\n    deleteTrip(input: $input) {\n      ... on DeleteTripPayload {\n        __typename\n        tripId\n      }\n\n      ... on DeleteTripFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n": types.DeleteTripDocument,
    "\n  mutation SendTripInvitation($input: SendTripInvitationInput!) {\n    sendTripInvitation(input: $input) {\n      ... on SendTripInvitationPayload {\n        __typename\n        tripId\n      }\n\n      ... on SendTripInvitationFailure {\n        __typename\n        problems {\n          ...ProblemBox_ProblemFragment\n        }\n      }\n    }\n  }\n": types.SendTripInvitationDocument,
    "\n  mutation KickTripMember($input: KickTripMemberInput!) {\n    kickTripMember(input: $input) {\n      ... on KickTripMemberPayload {\n        __typename\n        tripId\n      }\n      ... on KickTripMemberFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n": types.KickTripMemberDocument,
    "\n  mutation LeaveTrip($input: LeaveTripInput!) {\n    leaveTrip(input: $input) {\n      __typename\n      ... on LeaveTripPayload {\n        tripId\n      }\n\n      ... on LeaveTripFailure {\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n": types.LeaveTripDocument,
    "\n  mutation RenameTrip($input: RenameTripInput!) {\n    renameTrip(input: $input) {\n      ... on RenameTripPayload {\n        __typename\n        tripId\n        name\n      }\n\n      ... on RenameTripFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n": types.RenameTripDocument,
    "\n  mutation RescindTripInvitation($input: RescindTripInvitationInput!) {\n    rescindTripInvitation(input: $input) {\n      __typename\n      ... on RescindTripInvitationPayload {\n        tripId\n      }\n      ... on RescindTripInvitationFailure {\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n": types.RescindTripInvitationDocument,
    "\n  mutation TransferTripOwnership($input: TransferTripOwnershipInput!) {\n    transferTripOwnership(input: $input) {\n      ... on TransferTripOwnershipPayload {\n        __typename\n        tripId\n      }\n\n      ... on TransferTripOwnershipFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n": types.TransferTripOwnershipDocument,
    "\n  fragment TripDetails on Trip {\n    id\n    name\n    ownerUserId\n    privacyLevel\n    createdOnUtc\n    startTimeUtc\n    endTimeUtc\n    members {\n      userId\n      profile {\n        userId\n        nickname\n        picture\n      }\n      roles\n    }\n    invitations {\n      toUserId\n      recipient {\n        userId\n        nickname\n        picture\n      }\n    }\n  }\n": types.TripDetailsFragmentDoc,
    "\n    query TripDetails($tripId: UUID!) {\n      my {\n        trips(where: { id: { eq: $tripId } }) {\n          nodes {\n            ...TripDetails\n          }\n        }\n      }\n    }\n  ": types.TripDetailsDocument,
    "\n  mutation AcceptTripInvitation($input: AcceptTripInvitationInput!) {\n    acceptTripInvitation(input: $input) {\n      __typename\n      ... on AcceptTripInvitationPayload {\n        tripId\n      }\n\n      ... on AcceptTripInvitationFailure {\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n    }\n  }\n": types.AcceptTripInvitationDocument,
    "\n  mutation DeclineTripInvitation($input: DeclineTripInvitationInput!) {\n    declineTripInvitation(input: $input) {\n      __typename\n      ... on DeclineTripInvitationPayload {\n        __typename\n        tripId\n      }\n\n      ... on DeclineTripInvitationFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n    }\n  }\n": types.DeclineTripInvitationDocument,
    "\n  query MyTripInvitations {\n    my {\n      tripInvitations {\n        nodes {\n          ...MyTripInvitations_TripInvitationFragment\n        }\n      }\n    }\n  }\n": types.MyTripInvitationsDocument,
    "\n  fragment MyTripInvitations_TripInvitationFragment on TripInvitation {\n    trip {\n      id\n      name\n      startTimeUtc\n      endTimeUtc\n    }\n    sender {\n      nickname\n      picture\n    }\n  }\n": types.MyTripInvitations_TripInvitationFragmentFragmentDoc,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddGear($input: AddGearInput!) {\n    addGear(input: $input) {\n      ... on AddGearFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n      ... on AddGearPayload {\n        __typename\n        gear {\n          id\n          userId\n          name\n          brand\n          tags\n          quantity\n          weight {\n            value\n            unit\n          }\n          createdOnUtc\n          updatedOnUtc\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddGear($input: AddGearInput!) {\n    addGear(input: $input) {\n      ... on AddGearFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n      ... on AddGearPayload {\n        __typename\n        gear {\n          id\n          userId\n          name\n          brand\n          tags\n          quantity\n          weight {\n            value\n            unit\n          }\n          createdOnUtc\n          updatedOnUtc\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EditGearInfo($input: EditGearInfoInput!) {\n    editGearInfo(input: $input) {\n      ... on EditGearInfoFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n      ... on EditGearInfoPayload {\n        __typename\n        gear {\n          id\n          userId\n          name\n          brand\n          tags\n          quantity\n          weight {\n            value\n            unit\n          }\n          createdOnUtc\n          updatedOnUtc\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation EditGearInfo($input: EditGearInfoInput!) {\n    editGearInfo(input: $input) {\n      ... on EditGearInfoFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n      ... on EditGearInfoPayload {\n        __typename\n        gear {\n          id\n          userId\n          name\n          brand\n          tags\n          quantity\n          weight {\n            value\n            unit\n          }\n          createdOnUtc\n          updatedOnUtc\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RemoveGear($gearId: UUID!) {\n    removeGear(input: { gearId: $gearId }) {\n      ... on RemoveGearFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n      ... on RemoveGearPayload {\n        __typename\n        gearId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveGear($gearId: UUID!) {\n    removeGear(input: { gearId: $gearId }) {\n      ... on RemoveGearFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n      ... on RemoveGearPayload {\n        __typename\n        gearId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MyGear(\n    $first: Int!\n    $cursor: String\n    $filter: GearFilterInput\n    $sort: [GearSortInput!]\n    $search: String\n  ) {\n    my {\n      gearRoom {\n        gear(\n          search: $search\n          first: $first\n          after: $cursor\n          where: $filter\n          order: $sort\n        ) {\n          nodes {\n            id\n            name\n            brand\n            quantity\n            tags\n            userId\n            weight {\n              value\n              unit\n            }\n            createdOnUtc\n            updatedOnUtc\n          }\n          totalCount\n          pageInfo {\n            hasPreviousPage\n            hasNextPage\n            startCursor\n            endCursor\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query MyGear(\n    $first: Int!\n    $cursor: String\n    $filter: GearFilterInput\n    $sort: [GearSortInput!]\n    $search: String\n  ) {\n    my {\n      gearRoom {\n        gear(\n          search: $search\n          first: $first\n          after: $cursor\n          where: $filter\n          order: $sort\n        ) {\n          nodes {\n            id\n            name\n            brand\n            quantity\n            tags\n            userId\n            weight {\n              value\n              unit\n            }\n            createdOnUtc\n            updatedOnUtc\n          }\n          totalCount\n          pageInfo {\n            hasPreviousPage\n            hasNextPage\n            startCursor\n            endCursor\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MyGearRoomTags {\n    my {\n      gearRoom {\n        tags {\n          totalCount\n          nodes\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query MyGearRoomTags {\n    my {\n      gearRoom {\n        tags {\n          totalCount\n          nodes\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SearchProfiles($queryString: String!) {\n    searchProfiles(input: { query: $queryString, page: 0 }) {\n      nodes {\n        userId\n        fullName\n        ...SearchProfiles_ProfileFragment\n      }\n      pageInfo {\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query SearchProfiles($queryString: String!) {\n    searchProfiles(input: { query: $queryString, page: 0 }) {\n      nodes {\n        userId\n        fullName\n        ...SearchProfiles_ProfileFragment\n      }\n      pageInfo {\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SearchProfiles_ProfileFragment on Profile {\n    nickname\n    userId\n    picture\n    fullName\n  }\n"): (typeof documents)["\n  fragment SearchProfiles_ProfileFragment on Profile {\n    nickname\n    userId\n    picture\n    fullName\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ProblemBox_ProblemFragment on IProblem {\n    __typename\n    message\n  }\n"): (typeof documents)["\n  fragment ProblemBox_ProblemFragment on IProblem {\n    __typename\n    message\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateTrip($input: CreateTripInput!) {\n    createTrip(input: $input) {\n      ... on CreateTripPayload {\n        __typename\n        tripId\n      }\n\n      ... on CreateTripFailure {\n        __typename\n        problems {\n          ... on ValidationProblem {\n            field\n            message\n          }\n\n          ... on UserAlreadyInGroupProblem {\n            message\n          }\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n            message\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTrip($input: CreateTripInput!) {\n    createTrip(input: $input) {\n      ... on CreateTripPayload {\n        __typename\n        tripId\n      }\n\n      ... on CreateTripFailure {\n        __typename\n        problems {\n          ... on ValidationProblem {\n            field\n            message\n          }\n\n          ... on UserAlreadyInGroupProblem {\n            message\n          }\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n            message\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MyTripSummaries(\n    $first: Int!\n    $after: String\n    $filter: TripFilterInput\n    $sort: [TripSortInput!]\n  ) {\n    my {\n      trips(first: $first, after: $after, where: $filter, order: $sort) {\n        nodes {\n          ...TripSummary\n        }\n        totalCount\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query MyTripSummaries(\n    $first: Int!\n    $after: String\n    $filter: TripFilterInput\n    $sort: [TripSortInput!]\n  ) {\n    my {\n      trips(first: $first, after: $after, where: $filter, order: $sort) {\n        nodes {\n          ...TripSummary\n        }\n        totalCount\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TripSummary on Trip {\n    id\n    privacyLevel\n    name\n    startTimeUtc\n    endTimeUtc\n    members {\n      profile {\n        nickname\n        picture\n      }\n      userId\n      roles\n    }\n  }\n"): (typeof documents)["\n  fragment TripSummary on Trip {\n    id\n    privacyLevel\n    name\n    startTimeUtc\n    endTimeUtc\n    members {\n      profile {\n        nickname\n        picture\n      }\n      userId\n      roles\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AssignTripMemberRoles($input: AssignTripMemberRolesInput!) {\n    assignTripMemberRoles(input: $input) {\n      ... on AssignTripMemberRolesPayload {\n        __typename\n      }\n      ... on AssignTripMemberRolesFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AssignTripMemberRoles($input: AssignTripMemberRolesInput!) {\n    assignTripMemberRoles(input: $input) {\n      ... on AssignTripMemberRolesPayload {\n        __typename\n      }\n      ... on AssignTripMemberRolesFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteTrip($input: DeleteTripInput!) {\n    deleteTrip(input: $input) {\n      ... on DeleteTripPayload {\n        __typename\n        tripId\n      }\n\n      ... on DeleteTripFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteTrip($input: DeleteTripInput!) {\n    deleteTrip(input: $input) {\n      ... on DeleteTripPayload {\n        __typename\n        tripId\n      }\n\n      ... on DeleteTripFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SendTripInvitation($input: SendTripInvitationInput!) {\n    sendTripInvitation(input: $input) {\n      ... on SendTripInvitationPayload {\n        __typename\n        tripId\n      }\n\n      ... on SendTripInvitationFailure {\n        __typename\n        problems {\n          ...ProblemBox_ProblemFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SendTripInvitation($input: SendTripInvitationInput!) {\n    sendTripInvitation(input: $input) {\n      ... on SendTripInvitationPayload {\n        __typename\n        tripId\n      }\n\n      ... on SendTripInvitationFailure {\n        __typename\n        problems {\n          ...ProblemBox_ProblemFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation KickTripMember($input: KickTripMemberInput!) {\n    kickTripMember(input: $input) {\n      ... on KickTripMemberPayload {\n        __typename\n        tripId\n      }\n      ... on KickTripMemberFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation KickTripMember($input: KickTripMemberInput!) {\n    kickTripMember(input: $input) {\n      ... on KickTripMemberPayload {\n        __typename\n        tripId\n      }\n      ... on KickTripMemberFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation LeaveTrip($input: LeaveTripInput!) {\n    leaveTrip(input: $input) {\n      __typename\n      ... on LeaveTripPayload {\n        tripId\n      }\n\n      ... on LeaveTripFailure {\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation LeaveTrip($input: LeaveTripInput!) {\n    leaveTrip(input: $input) {\n      __typename\n      ... on LeaveTripPayload {\n        tripId\n      }\n\n      ... on LeaveTripFailure {\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RenameTrip($input: RenameTripInput!) {\n    renameTrip(input: $input) {\n      ... on RenameTripPayload {\n        __typename\n        tripId\n        name\n      }\n\n      ... on RenameTripFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RenameTrip($input: RenameTripInput!) {\n    renameTrip(input: $input) {\n      ... on RenameTripPayload {\n        __typename\n        tripId\n        name\n      }\n\n      ... on RenameTripFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RescindTripInvitation($input: RescindTripInvitationInput!) {\n    rescindTripInvitation(input: $input) {\n      __typename\n      ... on RescindTripInvitationPayload {\n        tripId\n      }\n      ... on RescindTripInvitationFailure {\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RescindTripInvitation($input: RescindTripInvitationInput!) {\n    rescindTripInvitation(input: $input) {\n      __typename\n      ... on RescindTripInvitationPayload {\n        tripId\n      }\n      ... on RescindTripInvitationFailure {\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation TransferTripOwnership($input: TransferTripOwnershipInput!) {\n    transferTripOwnership(input: $input) {\n      ... on TransferTripOwnershipPayload {\n        __typename\n        tripId\n      }\n\n      ... on TransferTripOwnershipFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation TransferTripOwnership($input: TransferTripOwnershipInput!) {\n    transferTripOwnership(input: $input) {\n      ... on TransferTripOwnershipPayload {\n        __typename\n        tripId\n      }\n\n      ... on TransferTripOwnershipFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            ...ProblemBox_ProblemFragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TripDetails on Trip {\n    id\n    name\n    ownerUserId\n    privacyLevel\n    createdOnUtc\n    startTimeUtc\n    endTimeUtc\n    members {\n      userId\n      profile {\n        userId\n        nickname\n        picture\n      }\n      roles\n    }\n    invitations {\n      toUserId\n      recipient {\n        userId\n        nickname\n        picture\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment TripDetails on Trip {\n    id\n    name\n    ownerUserId\n    privacyLevel\n    createdOnUtc\n    startTimeUtc\n    endTimeUtc\n    members {\n      userId\n      profile {\n        userId\n        nickname\n        picture\n      }\n      roles\n    }\n    invitations {\n      toUserId\n      recipient {\n        userId\n        nickname\n        picture\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query TripDetails($tripId: UUID!) {\n      my {\n        trips(where: { id: { eq: $tripId } }) {\n          nodes {\n            ...TripDetails\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    query TripDetails($tripId: UUID!) {\n      my {\n        trips(where: { id: { eq: $tripId } }) {\n          nodes {\n            ...TripDetails\n          }\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AcceptTripInvitation($input: AcceptTripInvitationInput!) {\n    acceptTripInvitation(input: $input) {\n      __typename\n      ... on AcceptTripInvitationPayload {\n        tripId\n      }\n\n      ... on AcceptTripInvitationFailure {\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AcceptTripInvitation($input: AcceptTripInvitationInput!) {\n    acceptTripInvitation(input: $input) {\n      __typename\n      ... on AcceptTripInvitationPayload {\n        tripId\n      }\n\n      ... on AcceptTripInvitationFailure {\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeclineTripInvitation($input: DeclineTripInvitationInput!) {\n    declineTripInvitation(input: $input) {\n      __typename\n      ... on DeclineTripInvitationPayload {\n        __typename\n        tripId\n      }\n\n      ... on DeclineTripInvitationFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeclineTripInvitation($input: DeclineTripInvitationInput!) {\n    declineTripInvitation(input: $input) {\n      __typename\n      ... on DeclineTripInvitationPayload {\n        __typename\n        tripId\n      }\n\n      ... on DeclineTripInvitationFailure {\n        __typename\n        problems {\n          ... on IProblem {\n            message\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MyTripInvitations {\n    my {\n      tripInvitations {\n        nodes {\n          ...MyTripInvitations_TripInvitationFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query MyTripInvitations {\n    my {\n      tripInvitations {\n        nodes {\n          ...MyTripInvitations_TripInvitationFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MyTripInvitations_TripInvitationFragment on TripInvitation {\n    trip {\n      id\n      name\n      startTimeUtc\n      endTimeUtc\n    }\n    sender {\n      nickname\n      picture\n    }\n  }\n"): (typeof documents)["\n  fragment MyTripInvitations_TripInvitationFragment on TripInvitation {\n    trip {\n      id\n      name\n      startTimeUtc\n      endTimeUtc\n    }\n    sender {\n      nickname\n      picture\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;