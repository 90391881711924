import { RenderIf } from '@knapsak/shared/ui-render-if';
import {
  FragmentType,
  getFragmentData,
  graphql
} from '@knapsak/web/shared/data-access';
import { Box, Text } from '@mantine/core';

const ProblemFragment = graphql(`
  fragment ProblemBox_ProblemFragment on IProblem {
    __typename
    message
  }
`);

interface Props {
  problems: FragmentType<typeof ProblemFragment>[];
}

export const ProblemBox = (props: Props) => {
  const problems = getFragmentData(ProblemFragment, props.problems);

  return (
    <RenderIf condition={problems.length > 0}>
      <Box
        p="sm"
        style={{
          padding: '1rem'
        }}
      >
        {problems.map((p, idx) => (
          <Text key={idx} c="red" w="bold">
            <span>
              <li>{p.message}</li>
            </span>
          </Text>
        ))}
      </Box>
    </RenderIf>
  );
};
