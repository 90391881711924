export const ROUTES = {
  Landing: () => '/',
  Login: () => '/auth/login',
  Logout: () => '/auth/logout',
  Callback: () => '/auth/callback',
  MyTrips: () => '/my/trips',
  MyUpcomingTrips: () => '/my/trips/upcoming',
  MyPastTrips: () => '/my/trips/past',
  MyGearRoom: () => '/my/gear',
  NewTrip: () => '/trip/new',
  TripDetails: (tripId = ':tripId') => `/trip/${tripId}`
};
