import {
  FragmentType,
  getFragmentData,
  graphql
} from '@knapsak/web/shared/data-access';
import { Avatar, Box, Flex, Group, Text } from '@mantine/core';
import { AcceptInvitationButton } from '../AcceptInvitationButton/AcceptInvitation';
import { DeclineInvitationButton } from '../DeclineInvitationButton/DeclineInvitationButton';

export const MyTripInvitations_TripInvitationFragment = graphql(`
  fragment MyTripInvitations_TripInvitationFragment on TripInvitation {
    trip {
      id
      name
      startTime
      endTime
    }
    sender {
      nickname
      picture
    }
  }
`);

interface Props {
  invitation: FragmentType<typeof MyTripInvitations_TripInvitationFragment>;
}

const TripInvitationListItem = (props: Props) => {
  const invitation = getFragmentData(
    MyTripInvitations_TripInvitationFragment,
    props.invitation
  );

  const toDateString = (date: string) => {
    const d = new Date(date);
    return d.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    });
  };

  return (
    <Flex key={invitation.trip.id} direction="column" align="center" p="lg">
      <Group>
        <Avatar src={invitation.sender?.picture} radius="xl" />
        <Box dir="col">
          <Text>
            <b>{invitation.sender.nickname}</b> invited you to join{' '}
            <b>{invitation.trip?.name}</b>.
          </Text>
          <Text color="gray.6">
            {toDateString(invitation.trip?.startTime)} -{' '}
            {toDateString(invitation.trip?.endTime)}
          </Text>
        </Box>
      </Group>
      <Group mt="sm">
        <AcceptInvitationButton
          tripId={invitation.trip.id}
          tripName={invitation.trip.name}
        />
        <DeclineInvitationButton
          tripId={invitation.trip.id}
          tripName={invitation.trip.name}
        />
      </Group>
    </Flex>
  );
};

export default TripInvitationListItem;
