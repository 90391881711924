import { RenderIf } from '@knapsak/shared/ui-render-if';
import { ROUTES } from '@knapsak/web/navigation/util-routes';
import {
  ProfileSearch,
  ProfileSearchResult
} from '@knapsak/web/profiles/feature-search';
import {
  CreateTripInput,
  TripPrivacyLevel,
  graphql,
  useKnapsakApi
} from '@knapsak/web/shared/data-access';
import { ProblemBox } from '@knapsak/web/shared/feature-problems';
import { SimpleOops } from '@knapsak/web/shared/ui-errors';
import {
  Box,
  Button,
  Flex,
  Grid,
  Group,
  SegmentedControl,
  Text,
  TextInput
} from '@mantine/core';
import { DateTimePicker, DateValue } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useMutation } from '@tanstack/react-query';
import { Form, Navigate } from 'react-router-dom';
import { InvitedUser } from './InvitedUser';

const CreateTripMutation = graphql(`
  mutation CreateTrip($input: CreateTripInput!) {
    createTrip(input: $input) {
      ... on CreateTripPayload {
        __typename
        tripId
      }

      ... on CreateTripFailure {
        __typename
        problems {
          ... on ValidationProblem {
            field
            message
          }

          ... on UserAlreadyInGroupProblem {
            message
          }
          ... on IProblem {
            ...ProblemBox_ProblemFragment
            message
          }
        }
      }
    }
  }
`);

interface TripFormData {
  name: string;
  privacyLevel: string;
  startDateTime: DateValue;
  endDateTime: DateValue;
  invitedUsers: ProfileSearchResult[];
}

export const CreateTripForm = () => {
  const knapsakApiClient = useKnapsakApi();

  const {
    data: result,
    mutate,
    isError
  } = useMutation({
    mutationFn: async (input: CreateTripInput) =>
      await knapsakApiClient.request(CreateTripMutation, { input })
  });

  const form = useForm<TripFormData>({
    initialValues: {
      name: '',
      privacyLevel: 'PRIVATE',
      startDateTime: new Date(),
      endDateTime: new Date(),
      invitedUsers: []
    }
  });

  const problems =
    result?.createTrip?.__typename === 'CreateTripFailure'
      ? result?.createTrip.problems
      : undefined;

  if (result?.createTrip?.__typename === 'CreateTripPayload') {
    return <Navigate to={ROUTES.TripDetails(result?.createTrip?.tripId)} />;
  }

  const otherProblems =
    problems?.filter((p) => p.__typename === undefined) ?? [];

  const getFieldError = (fieldName: string) =>
    problems
      ?.filter(
        (p) => p.__typename === 'ValidationProblem' && p.field === fieldName
      )
      .at(0);

  const onProfileSelected = (profile: ProfileSearchResult) => {
    if (form.values.invitedUsers.includes(profile)) {
      return;
    }

    form.setValues({
      ...form.values,
      invitedUsers: [...form.values.invitedUsers, profile]
    });
  };

  return (
    <>
      <RenderIf condition={isError}>
        <SimpleOops />
      </RenderIf>
      <ProblemBox problems={otherProblems} />
      <Form method="post">
        <Grid justify="center" gutter="md" columns={6}>
          <Grid.Col span={6}>
            <TextInput
              label="Name"
              name="name"
              type="text"
              withAsterisk
              {...form.getInputProps('name')}
              error={getFieldError('Name')?.message}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Text mb="md" fz="lg" w="bold">
              Privacy
            </Text>
            <SegmentedControl
              name="privacyLevel"
              data={['PUBLIC', 'PRIVATE']}
              {...form.getInputProps('privacyLevel')}
            />
            <Text mt="md" fz="lg" w="bold">
              Duration
            </Text>
            <Group grow>
              <DateTimePicker
                label="Starting"
                name="endDateTime"
                valueFormat="DD MMM YYYY hh:mm A"
                error={getFieldError('StartTime')?.message}
                withAsterisk
                value={form.values.startDateTime}
                onChange={(value) =>
                  form.setValues({ ...form.values, startDateTime: value })
                }
              />
              <DateTimePicker
                label="Ending"
                name="endDateTime"
                valueFormat="DD MMM YYYY hh:mm A"
                error={getFieldError('EndTime')?.message}
                withAsterisk
                value={form.values.endDateTime}
                onChange={(value) =>
                  form.setValues({ ...form.values, endDateTime: value })
                }
              />
            </Group>
          </Grid.Col>
          <Grid.Col span={3}>
            <Box>
              <Text fz="lg" mb="xs" w="bold">
                Team
              </Text>
              <ProfileSearch
                disabled={false}
                onProfileSelected={onProfileSelected}
              />
              <Box bg="gray" mt="xs" h={'275px'}>
                <RenderIf condition={form.values.invitedUsers.length > 0}>
                  {form.values.invitedUsers.map(
                    (profile: ProfileSearchResult) => (
                      <InvitedUser
                        key={profile.userId}
                        fullName={profile.fullName}
                        picture={profile.picture}
                        nickname={profile.nickname}
                        onRemove={() => {
                          form.setValues({
                            ...form.values,
                            invitedUsers: form.values.invitedUsers.filter(
                              (p) => p.userId !== profile.userId
                            )
                          });
                        }}
                      />
                    )
                  )}
                </RenderIf>
                <RenderIf condition={form.values.invitedUsers.length === 0}>
                  <Flex
                    justify="center"
                    align="center"
                    direction="column"
                    h="100%"
                  >
                    <Text ta="center" fz="sm">
                      No invitations.
                    </Text>
                  </Flex>
                </RenderIf>
              </Box>
            </Box>
          </Grid.Col>
          <Grid.Col span={6}>
            <Flex justify="center">
              <Button
                m={'1rem'}
                onClick={() => {
                  mutate({
                    name: form.values.name,
                    privacyLevel: form.values.privacyLevel as TripPrivacyLevel,
                    startTime: form.values.startDateTime?.toISOString(),
                    endTime: form.values.endDateTime?.toISOString(),
                    invitedUsers: form.values.invitedUsers.map((p) => p.userId)
                  });
                }}
              >
                Create
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </Form>
    </>
  );
};
