import { fromApi, toUnitOfWeight } from '@knapsak/web/gear/data-access';
import { Gear } from '@knapsak/web/gear/models';
import { createNewGear } from '@knapsak/web/gear/util';
import {
  AddGearDocument,
  AddGearInput,
  AddGearMutation,
  EditGearInfoDocument,
  EditGearInfoInput,
  EditGearInfoMutation,
  MyGearDocument,
  MyGearQuery,
  MyGearRoomTagsDocument,
  RemoveGearDocument,
  RemoveGearInput,
  SortEnumType,
  useKnapsakApi
} from '@knapsak/web/shared/data-access';
import {
  Button,
  Group,
  Menu,
  Paper,
  PillGroup,
  Space,
  Text,
  TextInput,
  Title
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { MdOutlineFilterAlt } from 'react-icons/md';
import { GearList, GearSortOption, GearTag } from './components';

const getSortInput = (sort: GearSortOption) => {
  const sortInput = [];

  switch (sort.brand) {
    case 'asc':
      sortInput.push({ brand: SortEnumType.Asc });
      break;
    case 'desc':
      sortInput.push({ brand: SortEnumType.Desc });
      break;
  }

  switch (sort.name) {
    case 'asc':
      sortInput.push({ name: SortEnumType.Asc });
      break;
    case 'desc':
      sortInput.push({ name: SortEnumType.Desc });
      break;
  }

  switch (sort.createdOn) {
    case 'asc':
      sortInput.push({ createdOn: SortEnumType.Asc });
      break;
    case 'desc':
      sortInput.push({ createdOn: SortEnumType.Desc });
      break;
  }

  return sortInput;
};

export const GearRoomPage = () => {
  const knapsakClient = useKnapsakApi();
  const [gearList, setGearList] = useState<Gear[]>([]);
  const [tagsFilters, setTagsFiltes] = useState<string[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [searchFilterDebounced] = useDebouncedValue(searchFilter, 500);
  const [sort, setSort] = useState<GearSortOption>({
    brand: 'none',
    name: 'none',
    createdOn: 'desc'
  });

  const {
    data: gearRoom,
    fetchNextPage: fetchMoreGear,
    refetch: refetchGear,
    isLoading: isLoadingGear,
    hasNextPage: hasMoreGear
  } = useInfiniteQuery(
    ['my', 'gear'],
    (pageInfo) =>
      knapsakClient.request(MyGearDocument, {
        first: 50,
        cursor: pageInfo.pageParam,
        filter: {
          or: [
            { name: { like: searchFilter } },
            { brand: { like: searchFilter } }
          ],
          tags:
            tagsFilters.length > 0
              ? {
                  some: {
                    in: tagsFilters
                  }
                }
              : {}
        },
        sort: getSortInput(sort)
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => onGearRoomLoaded(data.pages),
      getNextPageParam: (lastPage) =>
        lastPage.my.gearRoom.gear?.pageInfo?.endCursor
    }
  );

  const { data: tagsData, refetch: refetchTags } = useQuery(
    ['my', 'gear-room', 'tags'],
    () => knapsakClient.request(MyGearRoomTagsDocument)
  );

  const tags = tagsData?.my.gearRoom.tags ?? [];

  const onGearRoomLoaded = (pages: MyGearQuery[]) => {
    const newGear = gearList.filter((g) => g.intent === 'create');
    const loadedGear =
      pages
        .map((p) => p.my.gearRoom.gear?.nodes?.map((v) => fromApi(v)) ?? [])
        .flat() ?? [];

    setGearList([...newGear, ...loadedGear]);
  };

  const { mutate: removeGear } = useMutation(
    async (input: RemoveGearInput) =>
      await knapsakClient.request(RemoveGearDocument, input)
  );

  const { mutate: addGear } = useMutation(
    async (input: AddGearInput) =>
      await knapsakClient.request(AddGearDocument, { input })
  );

  const { mutate: editGearInfo } = useMutation(
    async (input: EditGearInfoInput) =>
      await knapsakClient.request(EditGearInfoDocument, { input })
  );

  const openConfirmModal = (gear: Gear) =>
    modals.openConfirmModal({
      title: 'Confirm Delete',
      children: (
        <Text size="sm">
          Are you sure you want to delete{' '}
          <b>
            {gear.brand} {gear.name}
          </b>
          ?
        </Text>
      ),
      centered: true,
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => deleteGear(gear)
    });

  const newGear = () => {
    setGearList([createNewGear(), ...gearList]);
  };

  const onDeleteGear = (gear: Gear) => {
    if (gear.intent === 'create') deleteGear(gear);
    else openConfirmModal(gear);
  };

  const onInternalError = () => {
    notifications.show({
      title: 'Oops',
      message: 'Something went wrong, please try again later.'
    });
  };

  const deleteGear = (gear: Gear) => {
    const idx = gearList.findIndex((p) => p.id === gear.id);

    if (gear.intent === 'none') {
      removeGear(
        { gearId: gear.id },
        {
          onSuccess: () =>
            setGearList([
              ...gearList.slice(0, idx),
              ...gearList.slice(idx + 1)
            ]),
          onError: () => onInternalError()
        }
      );
    } else {
      setGearList([...gearList.slice(0, idx), ...gearList.slice(idx + 1)]);
    }
  };

  const submitGear = (gear: Gear, onSuccess: () => void) => {
    if (gear.intent === 'create') {
      addGear(
        {
          name: gear.name,
          brand: gear.brand,
          tags: gear.tags,
          weightValue: gear.weight.value,
          unitOfWeight: toUnitOfWeight(gear.weight.unit),
          quantity: gear.quantity
        },
        {
          onSuccess: (data) => {
            onGearAdded(gear.id, data);
          },
          onError: () => onInternalError()
        }
      );
    } else {
      editGearInfo(
        {
          gearId: gear.id,
          name: gear.name,
          brand: gear.brand,
          tags: gear.tags,
          weightValue: gear.weight.value,
          unitOfWeight: toUnitOfWeight(gear.weight.unit),
          quantity: gear.quantity
        },
        {
          onSuccess: (data) => {
            onGearInfoEditted(data);
            onSuccess();
          },
          onError: () => onInternalError()
        }
      );
    }
  };

  const onGearInfoEditted = (result: EditGearInfoMutation) => {
    if (result.editGearInfo?.__typename === 'EditGearInfoPayload') {
      const gear = fromApi(result.editGearInfo.gear);
      const idx = gearList.findIndex((p) => p.id === gear.id);
      const updatedGear = { ...gear, intent: 'none' } as Gear;
      setGearList([
        ...gearList.slice(0, idx),
        updatedGear,
        ...gearList.slice(idx + 1)
      ]);
      refetchTags();
    } else if (result.editGearInfo?.__typename === 'EditGearInfoFailure') {
      notifications.show({
        title: 'Error',
        color: 'red',
        message: result.editGearInfo.problems.map((p) => p.message).join('\n')
      });
    } else {
      onInternalError();
    }
  };

  const onGearAdded = (gearId: string, result: AddGearMutation) => {
    if (result.addGear?.__typename === 'AddGearPayload') {
      const gear = fromApi(result.addGear.gear);
      const idx = gearList.findIndex((p) => p.id === gearId);
      const updatedGear = { ...gear, intent: 'none' } as Gear;
      setGearList([
        ...gearList.slice(0, idx),
        updatedGear,
        ...gearList.slice(idx + 1)
      ]);
      refetchTags();
    } else if (result.addGear?.__typename === 'AddGearFailure') {
      notifications.show({
        title: 'Error',
        color: 'red',
        message: result.addGear.problems.map((p) => p.message).join('\n')
      });
    } else {
      onInternalError();
    }
  };

  const addTagFilter = (t: string) => {
    if (tagsFilters.findIndex((x) => t === x) === -1) {
      setTagsFiltes([...tagsFilters, t]);
    }
  };

  const removeTagFilter = (idx: number) => {
    setTagsFiltes([
      ...tagsFilters.slice(0, idx),
      ...tagsFilters.slice(idx + 1)
    ]);
  };

  useEffect(() => {
    refetchGear();
  }, [tagsFilters, searchFilterDebounced, sort, refetchGear]);

  return (
    <Paper m="lg">
      <Group justify="space-between">
        <Title>Gear Room</Title>
        <Button onClick={newGear}>Add Gear</Button>
      </Group>

      <Group mt="lg" wrap="nowrap">
        <TextInput
          placeholder="Search by brand or name..."
          onChange={(e) => setSearchFilter(e.target.value)}
          style={{ flexGrow: 1, flexBasis: '90%' }}
        />
        <Menu shadow="md">
          <Menu.Target>
            <Button variant="outline" style={{ flexShrink: 0 }}>
              <MdOutlineFilterAlt /> Tag Filter
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {tags.map((t) => (
              <Menu.Item key={`${t}`} onClick={() => addTagFilter(t)}>
                {t}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Group>
      <PillGroup size="md" mt="md">
        {tagsFilters.map((t, idx) => (
          <GearTag
            key={`${t}-${idx}`}
            name={t}
            onRemove={() => removeTagFilter(idx)}
            withRemoveButton
          />
        ))}
      </PillGroup>
      <Space h="xl" />
      <GearList
        gear={gearList}
        isLoading={isLoadingGear}
        hasMore={hasMoreGear ?? false}
        sort={sort}
        onDeleteGear={onDeleteGear}
        onSubmitGear={submitGear}
        onLoadMore={fetchMoreGear}
        onSortChanged={setSort}
      />
    </Paper>
  );
};
