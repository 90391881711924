import { useAuth } from '@knapsak/web/auth/data-access';
import { ROUTES } from '@knapsak/web/navigation/util-routes';
import { Navigate } from 'react-router-dom';

export const LandingPage = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={ROUTES.MyTrips()} />;
  }

  return <Navigate to="/auth/login" />;
};
