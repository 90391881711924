import { RenderIf } from '@knapsak/shared/ui-render-if';
import { ProfileSearchResult } from '@knapsak/web/profiles/feature-search';
import { graphql, useKnapsakApi } from '@knapsak/web/shared/data-access';
import { ProblemBox } from '@knapsak/web/shared/feature-problems';
import { Button, Container, Group, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const KickTripMemberMutation = graphql(`
  mutation KickTripMember($input: KickTripMemberInput!) {
    kickTripMember(input: $input) {
      ... on KickTripMemberPayload {
        __typename
        tripId
      }
      ... on KickTripMemberFailure {
        __typename
        problems {
          ... on IProblem {
            ...ProblemBox_ProblemFragment
          }
        }
      }
    }
  }
`);

export const KickUserModal = (
  props: ContextModalProps<{
    tripId: string;
    tripName: string;
    profile: ProfileSearchResult;
  }>
) => {
  const {
    id,
    context,
    innerProps: { tripId, tripName, profile }
  } = props;

  const knapsakApiClient = useKnapsakApi();
  const { data, mutate, reset, isError } = useMutation(
    async (input: any) =>
      await knapsakApiClient.request(KickTripMemberMutation, { input })
  );

  const close = () => {
    reset();
    context.closeModal(id);
  };

  const queryClient = useQueryClient();

  if (data?.kickTripMember?.__typename === 'KickTripMemberPayload') {
    showNotification({
      title: 'Kick User',
      message: `You have successfully kicked ${profile.nickname} from ${tripName}.`,
      color: 'green'
    });

    close();

    queryClient.invalidateQueries(['trip', 'details', tripId]);
  }

  return (
    <Container>
      <RenderIf condition={isError}>
        <Text c="red">Something went wrong. Please try again later.</Text>
      </RenderIf>
      <ProblemBox
        problems={
          data?.kickTripMember?.__typename === 'KickTripMemberFailure'
            ? data?.kickTripMember.problems
            : []
        }
      />
      <Text>
        Are you sure you want to kick <b>{profile.nickname}</b> from{' '}
        <b>{tripName}</b>?
      </Text>
      <Group mt="lg" justify="center">
        <Button
          color="red"
          onClick={() => mutate({ tripId, userId: profile.userId })}
        >
          Kick
        </Button>
        <Button color="gray" onClick={close}>
          Cancel
        </Button>
      </Group>
    </Container>
  );
};
