import { WeightUnit } from '@knapsak/web/gear/models';
import { Group, NumberInput, Select } from '@mantine/core';
import { useWeightInput } from './useWeightInput';

export const WeightInput = () => {
  const { form, changeWeight, changeUnit } = useWeightInput();

  return (
    <Group wrap="nowrap" gap="0">
      <NumberInput
        decimalScale={2}
        min={0}
        styles={{ input: { textAlign: 'right' } }}
        {...form.getInputProps('weight.value')}
        onChange={(v) => changeWeight(v)}
      />
      <Select
        data={['g', 'oz', 'lb']}
        {...form.getInputProps('weight.unit')}
        onChange={(v) => changeUnit(v as WeightUnit)}
      />
    </Group>
  );
};
