import { RenderIf } from '@knapsak/shared/ui-render-if';
import { ROUTES } from '@knapsak/web/navigation/util-routes';
import { graphql, useKnapsakApi } from '@knapsak/web/shared/data-access';
import { ProblemBox } from '@knapsak/web/shared/feature-problems';
import { Button, Container, Group, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const DeleteTripMutation = graphql(`
  mutation DeleteTrip($input: DeleteTripInput!) {
    deleteTrip(input: $input) {
      ... on DeleteTripPayload {
        __typename
        tripId
      }

      ... on DeleteTripFailure {
        __typename
        problems {
          ... on IProblem {
            ...ProblemBox_ProblemFragment
          }
        }
      }
    }
  }
`);

export const DeleteTripModal = (
  props: ContextModalProps<{ tripId: string; tripName: string }>
) => {
  const {
    id,
    context,
    innerProps: { tripId, tripName }
  } = props;
  const knapsakApiClient = useKnapsakApi();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data, reset, isError, mutate } = useMutation({
    mutationFn: async (input: any) =>
      await knapsakApiClient.request(DeleteTripMutation, { input })
  });

  const close = () => {
    reset();
    context.closeModal(id);
  };

  if (data?.deleteTrip?.__typename === 'DeleteTripPayload') {
    showNotification({
      title: 'Delete Trip',
      message: `Successfully deleted ${tripName}`,
      color: 'green'
    });
    close();
    navigate(ROUTES.MyTrips());
    queryClient.invalidateQueries(['my', 'trips']);
  }

  return (
    <Container>
      <RenderIf condition={isError}>
        <Text c="red">Something went wrong. Please try again later.</Text>
      </RenderIf>
      <ProblemBox
        problems={
          data?.deleteTrip?.__typename === 'DeleteTripFailure'
            ? data.deleteTrip.problems
            : []
        }
      />
      <Text>
        Are you sure you want to delete <b>{tripName}</b>?
      </Text>
      <Text mt="lg">
        You cannot undo this action. This will permanently delete the trip and
        any data associated with it.
      </Text>
      <Group justify="center" mt="lg">
        <Button color="red" onClick={() => mutate({ tripId })}>
          Delete
        </Button>
        <Button color="gray" onClick={close}>
          Cancel
        </Button>
      </Group>
    </Container>
  );
};
