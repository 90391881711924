import { RenderIf } from '@knapsak/shared/ui-render-if';
import { ROUTES } from '@knapsak/web/navigation/util-routes';
import { graphql, useKnapsakApi } from '@knapsak/web/shared/data-access';
import { ProblemBox } from '@knapsak/web/shared/feature-problems';
import { Button, Container, Group, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const LeaveTripMutation = graphql(`
  mutation LeaveTrip($input: LeaveTripInput!) {
    leaveTrip(input: $input) {
      __typename
      ... on LeaveTripPayload {
        tripId
      }

      ... on LeaveTripFailure {
        problems {
          ... on IProblem {
            ...ProblemBox_ProblemFragment
          }
        }
      }
    }
  }
`);

export const LeaveTripModal = (
  props: ContextModalProps<{ tripId: string; tripName: string }>
) => {
  const {
    id,
    context,
    innerProps: { tripId, tripName }
  } = props;

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const knapsakApiClient = useKnapsakApi();
  const {
    data: result,
    mutate,
    reset,
    isError
  } = useMutation(
    async (input: any) =>
      await knapsakApiClient.request(LeaveTripMutation, { input })
  );

  const close = () => {
    reset();
    context.closeModal(id);
  };

  if (result?.leaveTrip?.__typename === 'LeaveTripPayload') {
    showNotification({
      title: 'Leave Trip',
      message: `You left ${tripName}.`,
      color: 'green'
    });

    close();
    queryClient.invalidateQueries(['my', 'trips']);
    navigate(ROUTES.MyTrips());
  }

  return (
    <>
      <Container>
        <ProblemBox
          problems={
            result?.leaveTrip?.__typename === 'LeaveTripFailure'
              ? result.leaveTrip.problems
              : []
          }
        />
        <RenderIf condition={isError}>
          <Text c="red">Something went wrong. Please try again later.</Text>
        </RenderIf>
        <Text>
          Are you sure you want to leave <b>{tripName}</b>?
        </Text>
        <Text mt="xl">
          Once you leave, you will no longer receive updates or be allowed to
          participate in <b>{tripName}</b>.
        </Text>
        <Text mt="xl">
          If <b>{tripName}</b> is private, you will no longer be able to view
          it.
        </Text>
      </Container>
      <Group mt="lg" justify="center">
        <Button color="red" onClick={() => mutate({ tripId })}>
          Leave
        </Button>
        <Button color="gray" onClick={close}>
          Cancel
        </Button>
      </Group>
    </>
  );
};
