import { ActionIcon, Group, Stack, Text } from '@mantine/core';
import { useCallback } from 'react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';

export type SortDirection = 'asc' | 'desc' | 'none';

type SortableColumnProps = {
  text: string;
  sortOption: SortDirection;
  onSortChanged: (sort: SortDirection) => void;
};

export const SortableColumn = (props: SortableColumnProps) => {
  const { text, sortOption, onSortChanged } = props;

  const unselectedColor = 'gray';
  const selectedColor = 'white';

  const sort = useCallback(
    (direction: SortDirection) => {
      if (sortOption === direction) {
        onSortChanged('none');
      } else {
        onSortChanged(direction);
      }
    },
    [sortOption, onSortChanged]
  );

  const ascColor = sortOption === 'asc' ? selectedColor : unselectedColor;
  const descColor = sortOption === 'desc' ? selectedColor : unselectedColor;

  return (
    <Group>
      <Text fw={700}>{text}</Text>
      <Stack gap={0}>
        <ActionIcon onClick={() => sort('asc')} size="xs">
          <IoChevronUp color={ascColor} />
        </ActionIcon>
        <ActionIcon onClick={() => sort('desc')} size="xs">
          <IoChevronDown color={descColor} />
        </ActionIcon>
      </Stack>
    </Group>
  );
};
